import {
  faCalendarDays,
  faComments,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowsUpDownLeftRight,
  faHeart,
  faRotate,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import fewture1 from "../../assets/fewture-product/fewture1.png";
import fewture2 from "../../assets/fewture-product/fewture2.png";
import fewture5 from "../../assets/fewture-product/fewture5.png";
import fewture6 from "../../assets/fewture-product/fewture6.png";
import fewture7 from "../../assets/fewture-product/fewture7.png";
import fewture8 from "../../assets/fewture-product/fewture8.png";
import fewture9 from "../../assets/fewture-product/fewture9.png";
import fewture10 from "../../assets/fewture-product/fewture10.png";
import switter from "../../assets/fewture-product/switter.png";
import switter2 from "../../assets/fewture-product/switter2.png";
import shirt from "../../assets/fewture-product/shirt.png";
import shirt2 from "../../assets/fewture-product/shirt2.png";
import cort from "../../assets/fewture-product/cort.png";
import cort2 from "../../assets/fewture-product/cort2.png";
import woman from "../../assets/fewture-product/woman.png";
import woman2 from "../../assets/fewture-product/woman2.png";
import blog1 from "../../assets/blog/blog1.jpg";
import blog2 from "../../assets/blog/blog2.jpg";
import blog3 from "../../assets/blog/blog3.jpg";
import Retuns from "../../assets/fewture/returns.png";
import security from "../../assets/fewture/security.png";
import shiping from "../../assets/fewture/shipping.png";
import support from "../../assets/fewture/support.png";
import backgrounds from "../../assets/newsletter/newsletter1.png";
import newsletter2 from "../../assets/newsletter/newsletter2.png";
import shap1 from "../../assets/promo/promo-bg-1.png";
import shap2 from "../../assets/promo/promo-bg-2.png";
import shap3 from "../../assets/promo/promo-bg-3.png";
import shapimg1 from "../../assets/promo/promo-img-1.png";
import shapimg2 from "../../assets/promo/promo-img-2.png";
import shapimg3 from "../../assets/promo/promo-img-3.png";
import BlogCard from "../../components/blogcard/BlogCard";
import CategoriesSlider from "../../components/categories-slider/CategoriesSlider";
import DealProductSlider from "../../components/dealproductslider/DealProductSlider";
import Fewture from "../../components/features/Feature";
import FewtureProdectSlide from "../../components/fewtureproductslide/FewtureProdectSlide";
import HeroSection from "../../components/herosection/HeroSection";
import NewsLetter from "../../components/newsletter/NewsLetter";
import ProductCard from "../../components/productcard/ProductCard";
import PromoCard from "../../components/promocard/PromoCard";
import SectionTitle from "../../components/sectiontitle/SectionTitle";
import TestimonialSlide from "../../components/testimonialslide/TestimonialSlide";
import "./Home2.css";
const Home2 = () => {
  return (
    <Fragment>
      <HeroSection />
      {/* fewture-area-start */}
      <div className="fewture-area pt-70">
        <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 mb-20">
            <Fewture
              shiping={shiping}
              title="Free Shipping"
              contents="Shipping is on us for all U.S. orders or those over $100"
            />
          </div>
          <div className="col-lg-3  col-md-6 mb-20">
            <Fewture
              shiping={security}
              title="Money Return"
              contents="You have 30 days to easily exchange your item"
            />
          </div>
          <div className="col-lg-3  col-md-6 mb-20">
            <Fewture
              shiping={Retuns}
              title="Order Discount"
              contents="Within 30 days, easily return for an exchange"
            />
          </div>
          <div className="col-lg-3  col-md-6 mb-20 mb-0">
            <Fewture
              shiping={support}
              title="24/7 Support"
              contents="Reach out to us 24 hours a day, 7 days a week"
            />
          </div>
          </div>
        </div>
      </div>
      {/* fewture-area-end */}
      {/* categories-area-start */}
      <div className="categoris-area pt-70">
        <div className="container">
          <div className="total-cetagoris">
            <div className="row">
              <div className="col-lg-3">
                <SectionTitle
                  minititle="Categories"
                  title="Explore our top categories"
                />
              </div>
              <div className="col-lg-9">
                <CategoriesSlider />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* categories-area-end */}
      {/* promotional-area-start */}
      <div className="promotional-area pt-70 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-20">
              {/* single-promo */}
              <PromoCard
                shap1={shap1}
                promoimg1={shapimg1}
                title="Best Sale"
                bigtitle="Latest Collection"
                link="Shop Now"
                discount="43%"
                discountsof="off"
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              {/* single-promo */}
              <PromoCard
                className="promosection-default"
                shap1={shap2}
                promoimg1={shapimg2}
                title="Special Offer"
                bigtitle="Small Collection"
                link="Shop Now"
                discount="10%"
                discountsof="off"
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              {/* single-promo */}
              <PromoCard
                shap1={shap3}
                promoimg1={shapimg3}
                title="Supper Offer"
                bigtitle="Old Collection"
                link="Shop Now"
                discount="9%"
                discountsof="off"
              />
            </div>
          </div>
        </div>
      </div>
      {/* promotional-area-end */}
      {/* FewtureProduct-area-start */}
      <div className="FewtureProduct-area">
        <div className="main-color pt-70">
          <div className="container">
            <div className="row">
              <SectionTitle
                minititle="Features Product"
                title="Our Features Collection"
                className="mb-20"
              />
            </div>
            <div className="row">
              <div className="col-lg-12">
                <FewtureProdectSlide />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FewtureProduct-area-end */}
      {/* deal-product-area-start */}
      <div className="deal-product-area pt-70">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SectionTitle
                minititle="Deal Product"
                title="Deal Of The Day"
                className="mb-20"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <DealProductSlider />
            </div>
          </div>
        </div>
      </div>
      {/* deal-product-area-end */}
      {/* all-product-shop-area-start */}
      <div className="shop-section pt-70 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <SectionTitle
                minititle="All Product"
                title="Our Store Collection"
                className="mb-20"
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <div
                className="nav nav-tabs product-tabs"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active"
                  id="nav-best-sales"
                  data-bs-toggle="tab"
                  data-bs-target="#best-sales"
                  type="button"
                  role="tab"
                  aria-controls="best-sales"
                  aria-selected="true"
                >
                  Best Sellers
                </button>
                <button
                  className="nav-link"
                  id="nav-new-product"
                  data-bs-toggle="tab"
                  data-bs-target="#new-product"
                  type="button"
                  role="tab"
                  aria-controls="new-product"
                  aria-selected="false"
                >
                  New Products
                </button>
                <button
                  className="nav-link"
                  id="nav-sale-product"
                  data-bs-toggle="tab"
                  data-bs-target="#sale-product"
                  type="button"
                  role="tab"
                  aria-controls="sale-product"
                  aria-selected="false"
                >
                  Sale Products
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="best-sales"
                  role="tabpanel"
                  aria-labelledby="nav-best-sales"
                  tabIndex="0"
                >
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={fewture1}
                        img2={fewture2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Woman Fashion"
                        newrate="$120"
                        discount="New"
                        discountes={true}
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default"
                        sp="(23)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="shop-section"
                        classNames="discount new-dis"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={shirt2}
                        img2={shirt}
                        btn="Add To Cart"
                        review={faStar}
                        title="Man Shirt"
                        newrate="$185"
                        oldrate="$205"
                        discount="Sale"
                        discountes={true}
                        classNames="discount Sale"
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default1"
                        sp="(0)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="shop-section"
                        rating="rating"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                       img={cort}
                       img2={cort2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Man Cort"
                        newrate="$175"
                        discount="Sale"
                        discountes={true}
                        classNames="discount Sale"
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default2"
                        sp="(88)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="shop-section"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={woman}
                        img2={woman2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Woman Dress"
                        newrate="$180"
                        oldrate="$220"
                        discount="- 25%"
                        discountes={true}
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd"
                        sp="(26)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="shop-section"
                        classNames="discount"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={switter}
                        img2={switter2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Switter"
                        newrate="$320"
                        oldrate="$400"
                        discount="Sale"
                        discountes={true}
                        classNames="discount Sale"
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default2"
                        sp="(0)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="shop-section"
                        rating="rating"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={fewture5}
                        img2={fewture6}
                        btn="Add To Cart"
                        review={faStar}
                        title="Confort Farneture"
                        newrate="$150"
                        discount="New"
                        discountes={true}
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default1"
                        sp="(76)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="shop-section"
                        classNames="discount new-dis"
                      />
                    </div>
                    <div className="d-lg-none col-md-6 d-xxl-block col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={fewture7}
                        img2={fewture8}
                        btn="Add To Cart"
                        review={faStar}
                        title="man Stylish"
                        newrate="$120"
                        discount="Sale"
                        discountes={true}
                        classNames="discount Sale"
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default"
                        sp="(89)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="shop-section"
                      />
                    </div>
                    <div className="d-lg-none col-md-6 d-xxl-block col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={fewture9}
                        img2={fewture10}
                        btn="Add To Cart"
                        review={faStar}
                        title="Woman Bag"
                        newrate="$150"
                        discount="New"
                        discountes={true}
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default1"
                        sp="76)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="shop-section"
                        classNames="discount new-dis"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="new-product"
                  role="tabpanel"
                  aria-labelledby="nav-new-product"
                  tabIndex="0"
                >
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={switter}
                        img2={switter2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Switter"
                        newrate="$150"
                        discount="New"
                        discountes={true}
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default1"
                        sp="(34)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="shop-section"
                        classNames="discount new-dis"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={fewture1}
                        img2={fewture2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Woman Fastion"
                        newrate="$120"
                        discount="New"
                        discountes={true}
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default"
                        sp="(11)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="shop-section"
                        classNames="discount new-dis"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="sale-product"
                  role="tabpanel"
                  aria-labelledby="nav-sale-product"
                  tabIndex="0"
                >
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={woman}
                        img2={woman2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Woman Dress"
                        newrate="$180"
                        oldrate="$220"
                        discount="Sale"
                        discountes={true}
                        classNames="discount Sale"
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd"
                        sp="(13)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="shop-section"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={woman}
                        img2={woman2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Woman Dress"
                        newrate="$180"
                        oldrate="$220"
                        discount="Sale"
                        discountes={true}
                        classNames="discount Sale"
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd"
                        sp="(32)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="shop-section"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* all-product-shop-area-end */}
      {/* testimonial-area-start */}
      <div className="testimonial-area section-bg3 section-ptb">
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 col-lg-8">
              <SectionTitle
                title="What People Say About Us"
                className="mb-20 text-center"
              />
              <div className="testimonial-slider">
                <TestimonialSlide />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* testimonial-area-end */}
      {/* latest-blog-area-start */}
      <div className="blog-area pt-70 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                minititle="Blog"
                title="Latest Blog"
                className="mb-50 text-center"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-20">
              <BlogCard
                img={blog1}
                user={faUser}
                calender={faCalendarDays}
                commentsicon={faComments}
                comments="6 Comments"
                authorposition="By Admin"
                date="28 feb 2024"
                linktitle="Couples having a blast while shopping online together"
              />
            </div>
            <div className="col-lg-4 col-md-6  mb-20">
              <BlogCard
                img={blog2}
                user={faUser}
                calender={faCalendarDays}
                commentsicon={faComments}
                comments="3 Comments"
                authorposition="By Admin"
                date="27 feb 2024"
                linktitle="A young couple indulging in pointing at displays"
              />
            </div>
            <div className="col-lg-4 col-md-6  mb-20">
              <BlogCard
                img={blog3}
                user={faUser}
                calender={faCalendarDays}
                commentsicon={faComments}
                comments="1 Comments"
                authorposition="By Admin"
                date="27 feb 2024"
                linktitle="Female friends enjoying a shopping comfort of home"
              />
            </div>
          </div>
        </div>
      </div>
      {/* latest-blog-area-end */}
      {/* Our-Newsletter-area-start */}
      <div className="newsletter-area">
        <NewsLetter
          background={backgrounds}
          smalltitle="Our Newsletter"
          titles="Get weekly update. Sign up and get up to"
          parsent="20% off"
          title="your first purchase"
          img={newsletter2}
        />
      </div>
      {/* Our-Newsletter-area-end */}
    </Fragment>
  );
};
export default Home2;
