import React, { Fragment } from "react";
import backgrounds from "../../assets/newsletter/newsletter1.png";
import newsletter2 from "../../assets/newsletter/newsletter2.png";
import Discription from "../../components/discription/Discription";
import EmbroideredLeft from "../../components/embroideredleft/EmbroideredLeft";
import EmbroideredRight from "../../components/embroideredright/EmbroideredRight";
import FewtureProdectSlide from "../../components/fewtureproductslide/FewtureProdectSlide";
import HeroSectionv2 from "../../components/herosectionv2/HeroSectionv2";
import NewsLetter from "../../components/newsletter/NewsLetter";
import SectionTitle from "../../components/sectiontitle/SectionTitle";
import breadcrumb from "../../assets/hero/breadcrumb.jpg";
import "./ShopDetails.css";
const ShopDetails = () => {
  return (
    <Fragment>
      {/* herosectionv2-area-start */}
      <HeroSectionv2 title="Shop-Details" homelink="Home" breadcrumb={breadcrumb} pagename="Shop-Details" />
      {/* herosectionv2-area-end */}
      {/* embroidered-area-start */}
      <div className="embroidered-area pt-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 md-mb-30">
              <div className="embroidered-left">
                <div className="embroidered-img">
                  <EmbroideredLeft />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="embroidered-right">
                <EmbroideredRight />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* embroidered-area-end */}
      {/* description-area */}
      <div className="discription-area pt-70">
        <Discription />
      </div>
      {/* description-end */}
      {/* FewtureProduct-area-start */}
      <div className="pelated-products">
        <div className="pt-70">
          <div className="container">
            <div className="row">
              <SectionTitle
                minititle="Your Recently"
                title="Viewed Items"
                className="mb-20"
              />
            </div>
            <div className="row">
              <div className="col-lg-12">
                <FewtureProdectSlide />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FewtureProduct-area-end */}
      {/* Our-Newsletter-area-start */}
      <div className="newsletter-area">
        <NewsLetter
          background={backgrounds}
          smalltitle="Our Newsletter"
          titles="Get weekly update. Sign up and get up to"
          parsent="20% off"
          title="your first purchase"
          img={newsletter2}
        />
      </div>
      {/* Our-Newsletter-area-end */}
    </Fragment>
  );
};
export default ShopDetails;
