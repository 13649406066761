import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./HeaderSearch.css";
const HeaderSearch = (props) => {
  return (
    <Fragment>
      <div className="search-result">
        <div className="search-product">
          <div className="search-product-img">
            <Link to={"#"}>
              <img src={props.white} alt={props.alt} />
            </Link>
          </div>
          <div className="search-content">
            <div className="product-rating">
              <span className="rating-icon">
                <FontAwesomeIcon icon={props.star} />
                <FontAwesomeIcon icon={props.star} />
                <FontAwesomeIcon icon={props.star} />
                <FontAwesomeIcon icon={props.star} />
                <FontAwesomeIcon icon={props.herfStar} />
              </span>
              <span className="total-rating">
                <span>{props.rating}</span> {props.views}
              </span>
            </div>
            <h4 className="product-title">
              <Link to={"#"}>{props.protitle}</Link>
            </h4>
            <div className="product-price">
              <h4>
                {props.newprise} <del>{props.oldprise}</del>
              </h4>
            </div>
            <div className="product-cart">
              <Link to={"/cart"} className="cart-btn">
                <FontAwesomeIcon icon={props.shopingbag} />
              </Link>
              <Link to={"/wishlist"} className="cart-btn">
                <FontAwesomeIcon icon={props.heart} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default HeaderSearch;
