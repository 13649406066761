import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./ProductCard.css";
const FewtureProduct = (props) => {
  return (
    <Fragment>
      <div
        className={`fewture-single-product ${
          props.className ? props.className : ""
        }`}
      >
        <div
          className={`fewtureproduct-img ${
            props.classNam ? props.classNam : ""
          }`}
        >
          {props.discountes ? (
            <div className={`${props.classNames ? props.classNames : ""}`}>
              {props.discount}
            </div>
          ) : (
            ""
          )}
          <Link className="p-relative" to="/shopdetails">
            <img className={`${props.classimg2 ? props.classimg2 : ""}`} src={props.img} alt="img" />
            <img className={`${props.classimg ? props.classimg : ""}`}  src={props.img2} alt="img" />
          </Link>
          <div className={`fewture-cetagory-hover ${props.others ? props.others : ""}`}>
            <ul>
              <li>
                <Link to="wishlist">
                  <FontAwesomeIcon icon={props.heart} />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <FontAwesomeIcon icon={props.equal} />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <FontAwesomeIcon icon={props.rotate} />
                </Link>
              </li>
            </ul>
          </div>
          <div className="fewture-btn">
            <Link to="#">{props.btn}</Link>
          </div>
        </div>
        <div
          className={`fewture-cetagory-content ${
            props.classNamfew ? props.classNamfew : ""
          }`}
        >
          <div className="fewture-cetagory-info">
            <div
              className={`fewture-cetagory-variant ${
                props.className ? props.className : ""
              }`}
            >
              <span className="color-one"></span>
              <span className="color-two"></span>
              <span className="color-three"></span>
            </div>
            <div className={`fewture-cetagory-review ${props.rating?props.rating:""}`}>
              <FontAwesomeIcon icon={props.review} />
              <FontAwesomeIcon icon={props.review} />
              <FontAwesomeIcon icon={props.review} />
              <FontAwesomeIcon icon={props.review} />
              <FontAwesomeIcon icon={props.review} />
              <span>{props.sp}</span>
            </div>
          </div>
          <div className="fewture-cetagory-title">
            <Link to="/shopdetails">{props.title}</Link>
            <h3>
              {props.newrate?props.newrate:""}
              {props.oldrate ? <del>{props.oldrate}</del> : ""}
            </h3>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default FewtureProduct;
