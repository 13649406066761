import { faXmark } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import cartimg1 from "../../assets/cartlist/1.png";
import cartimg2 from "../../assets/cartlist/2.png";
import cartimg3 from "../../assets/cartlist/3.png";
import cartimg4 from "../../assets/cartlist/4.png";
import cartimg5 from "../../assets/cartlist/5.png";
import cartimg6 from "../../assets/cartlist/6.png";
import Retuns from "../../assets/fewture/returns.png";
import security from "../../assets/fewture/security.png";
import shiping from "../../assets/fewture/shipping.png";
import support from "../../assets/fewture/support.png";
import backgrounds from "../../assets/newsletter/newsletter1.png";
import newsletter2 from "../../assets/newsletter/newsletter2.png";
import Fewture from "../../components/features/Feature";
import HeroSectionv2 from "../../components/herosectionv2/HeroSectionv2";
import NewsLetter from "../../components/newsletter/NewsLetter";
import ProductCartList from "../../components/productcartlist/ProductCartList";
import breadcrumb from "../../assets/hero/breadcrumb.jpg";
import "./Wishlist.css";
const Wishlist = () => {
  return (
    <Fragment>
      {/* herosectionv2-area-start */}
      <HeroSectionv2 title="Wishlist" homelink="Home" breadcrumb={breadcrumb} pagename="Wishlist" />
      {/* herosectionv2-area-end */}
      {/* product-cart-list-start */}
      <div className="product-cart-list-area pt-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-xl-12">
              <div className="table-responsive table-responsive-md  table-responsive-lg">
                <table className="table">
                  <thead>
                    <tr bgcolor="">
                      <th scope="col">Products</th>
                      <th scope="col">Size</th>
                      <th scope="col">Price</th>
                      <th scope="col">Stock Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ProductCartList
                      img={cartimg2}
                      pdname="Slick Woman Drees"
                      size="X"
                      price="$120"
                      xmark={faXmark}
                      className="bg-white"
                      totalprice="$280"
                      cartbtn="Add to Cart"
                      stock={true}
                    />
                    <ProductCartList
                      img={cartimg1}
                      pdname="Black Woman Drees"
                      size="XL"
                      price="$140"
                      xmark={faXmark}
                      totalprice="$250"
                      cartbtn="Add to Cart"
                      stock={true}
                    />
                    <ProductCartList
                      img={cartimg4}
                      pdname="Red Woman Drees"
                      size="XL"
                      price="$160"
                      xmark={faXmark}
                      className="bg-white"
                      totalprice="$380"
                      cartbtn="Add to Cart"
                      stock={true}
                    />
                    <ProductCartList
                      img={cartimg3}
                      pdname="black Woman Drees"
                      size="XL"
                      price="$155"
                      xmark={faXmark}
                      totalprice="$120"
                      cartbtn="Add to Cart"
                      stock={true}
                    />
                    <ProductCartList
                      img={cartimg6}
                      pdname="Switter"
                      size="M"
                      price="$155"
                      xmark={faXmark}
                      totalprice="$140"
                      cartbtn="Add to Cart"
                      stock={true}
                    />
                    <ProductCartList
                      img={cartimg5}
                      pdname="Woman Shirt"
                      size="M"
                      price="$155"
                      xmark={faXmark}
                      totalprice="$80"
                      cartbtn="Add to Cart"
                      stock={true}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* product-cart-list-end */}
      {/* fewture-area-start */}
      <div className="fewture-area pt-70">
        <div className="container">
          <div className="fewture-heare">
            <div className="row">
              <div className="col-lg-3 col-md-6 mb-20">
                <Fewture
                  shiping={shiping}
                  title="Free Shipping"
                  contents="Free Shipping On All US Or Order Above $100"
                />
              </div>
              <div className="col-lg-3 col-md-6 mb-20">
                <Fewture
                  shiping={security}
                  title="Security Payment"
                  contents="Simply Return It Within 30 Days For An Exchange."
                />
              </div>
              <div className="col-lg-3 col-md-6 mb-20">
                <Fewture
                  shiping={Retuns}
                  title="14-Day Return"
                  contents="Simply Return It Within 30 Days For An Exchange."
                />
              </div>
              <div className="col-lg-3 col-md-6 mb-20">
                <Fewture
                  shiping={support}
                  title="24/7 Support"
                  contents="Contact Us 24 Hours A Day, 7 Day's Week"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* fewture-area-end */}
      {/* Our-Newsletter-area-start */}
      <div className="newsletter-area pt-70">
        <NewsLetter
          background={backgrounds}
          smalltitle="Our Newsletter"
          titles="Get weekly update. Sign up and get up to"
          parsent="20% off"
          title="your first purchase"
          img={newsletter2}
          alt=""
        />
      </div>
      {/* Our-Newsletter-area-end */}
    </Fragment>
  );
};
export default Wishlist;
