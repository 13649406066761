
import React, { Fragment } from "react";
import {useNavigate } from "react-router-dom";
import main1 from "../../assets/hero/main1.png";
import man from "../../assets/hero/man.png";
import heroleft from "../../assets/hero/hero-left.png";
import shape2 from "../../assets/hero/shape2.png";
import shape3 from "../../assets/hero/shape3.png";
import quin from "../../assets/hero/quin.png";
import Button from "../button/Button";
import "./HeroSection.css";
const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="hero-area">
        <div className="main-color">
          <img className="shape2" src={shape2} alt="shape" />
          <img className="shape3" src={shape3} alt="shape" />
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="hero-wraper">
                  <div className="hero-title">
                    <h2>
                      The Ultimate Fashion Collection<br/> for Men and Women
                    </h2>
                    <Button
                      buttonText="Shop Now"
                      onclickCallback={() => {
                        navigate("/shop");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-4">
                <div className="hero-left">
                  <img src={heroleft} alt="hero" />
                </div>
              </div>
              <div className="col-lg-6 col-md-5 col-4">
                <div className="main-img">
                  <img src={main1} alt="main1" />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-4">
                <div className="hero-content-right">
                  <div className="content">
                    <img src={man} alt="man" />
                    <div className="quin"><img  src={quin} alt="quin" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default HeroSection;
