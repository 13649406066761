import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../button/Button";
import "./BlogCard.css";
const BlogCard = (props) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="single-blog-card">
        <div className="blog-img">
          <img src={props.img} alt="alt" />
        </div>
        <div className="blog-meta">
          <div className="blog-author blog-info">
            <Link to={"#"}>
              <FontAwesomeIcon icon={props.user} />
              <span>{props.authorposition}</span>
            </Link>
          </div>
          <div className="blog-date blog-info">
            <Link to={"#"}>
              <FontAwesomeIcon icon={props.calender} />
              <span>{props.date}</span>
            </Link>
          </div>
          <div className="blog-comments blog-info">
            <Link to={"#"}>
              <FontAwesomeIcon icon={props.commentsicon} />
              <span>{props.comments}</span>
            </Link>
          </div>
        </div>
        <div className="blog-link">
          <h2>
            <Link to={"/blogdetails"}>{props.linktitle}</Link>
          </h2>
        </div>
        <Button
          buttonText="Read More"
          className="blog-btn button2"
          onclickCallback={() => {
            navigate("/blogdetails");
          }}
        />
      </div>
    </Fragment>
  );
};
export default BlogCard;
