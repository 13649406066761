import React, { Fragment } from "react";
import "./Categoris.css";
const Categoris = (props) => {
  return (
    <Fragment>
      <div
        className={`single-cetagoris ${props.className ? props.className : ""}`}
      >
        <div className="cetagoris-img">
          <img src={props.img} alt="cetagoris" />
        </div>
        <div className="cetagoris-title">
          <h5>{props.title}</h5>
          <span>{props.content}</span>
        </div>
      </div>
    </Fragment>
  );
};
export default Categoris;
