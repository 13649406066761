import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import "./Testimonial.css";
const Testimonial = (props) => {
  return (
    <Fragment>
      <div
        className={`testimonial-wraper ${
          props.className ? props.className : ""
        }`}
      >
        <div className="testimonial-top">
          <div className="testimonial-icon">
            <FontAwesomeIcon icon={props.icon} />
          </div>
          <div className="testimonial-rating">
            <FontAwesomeIcon icon={props.star} />
            <FontAwesomeIcon icon={props.star} />
            <FontAwesomeIcon icon={props.star} />
            <FontAwesomeIcon icon={props.star} />
            <FontAwesomeIcon icon={props.star} />
          </div>
        </div>
        <div className="testimonial-content">
          <p>{props.content}</p>
        </div>
        <div className="testimonial-author">
          <div className="author-img">
            <img src={props.img} alt="author" />
          </div>
          <div className="author-identy">
            <h2>{props.authorname}</h2>
            <h6>{props.position}</h6>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Testimonial;
