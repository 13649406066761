import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faBagShopping,
  faMagnifyingGlass,
  faStar,
  faStarHalfStroke,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import cartimg from "../../assets/cart-header/casual-man-dress.jpg";
import woman from "../../assets/cart-header/fashion-woman-top.jpg";
import blue from "../../assets/search-product/blue.jpg";
import white from "../../assets/search-product/white-swetier.jpg";
import Button from "../button/Button";
import CartToogler from "../cartToggler/CartToggler";
import HeaderSearch from "../headersearch/HeaderSearch";
import InputField from "../inputfild/InputFild";
import "./HeaderRight.css";
const HeaderRight = (props) => {
  const navigate = useNavigate();
  const [cartToggle, setCartToggle] = useState(false);
  const [searchToogler, setSearchToogler] = useState(false);
  return (
    <Fragment>
      <div className="header-right">
        <ul>
          <li
            onClick={() => {
              setSearchToogler(!searchToogler);
            }}
            className={`dyna bi ${searchToogler ? "bi-x-lg" : "bi bi-list"}`}
          >
            <FontAwesomeIcon icon={props.search} />
          </li>
          <li className="dyna">
            <Link to={"#"}>
              <FontAwesomeIcon icon={props.user} />
            </Link>
          </li>
          <li className="dyna">
            <Link to={"/wishlist"}>
              <FontAwesomeIcon icon={props.heart} />
              <span className="item-count">4</span>
            </Link>
          </li>
          <li
            onClick={() => {
              setCartToggle(!cartToggle);
            }}
            className={`dyna bi ${cartToggle ? "bi-x-lg" : "bi bi-list"}`}
          >
            <FontAwesomeIcon icon={props.shoping} />
            <span className="item-count">3</span>
          </li>
        </ul>
        {/* cart-toggler */}
        <div className={`cart-slide ${cartToggle ? "active" : ""}`}>
          <div className="cart-content">
            <div className="cart-header">
              <h2 className="cart-title">Cart View</h2>
              <button
                onClick={() => {
                  setCartToggle(!cartToggle);
                }}
                className={`cart-close ${cartToggle ? "active" : ""}`}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
            <CartToogler
              cartimg={cartimg}
              xmark={faXmark}
              star={faStar}
              star1={faStar}
              count="32"
              mandress="Woman black Dress"
              prise1="$15.00"
              alt="cartimg"
            />
            <CartToogler
              cartimg={woman}
              xmark={faXmark}
              star={faStar}
              star1={faStar}
              count="65"
              prise1="$15.00"
              mandress="Kirby T-Shirt"
              alt="woman"
              classNames="border-non"
            />
            <div className="cart-bottom">
              <h3 className="cart-subtotal">
                <span>Subtotal:</span>
                <span>$90.00</span>
              </h3>
              <div className="btn-group">
                <Button
                  onclickCallback={() => {
                    navigate("/cart");
                  }}
                  buttonText="View Cart"
                />
                <Button
                  onclickCallback={() => {
                    navigate("/checkout");
                  }}
                  className="button2"
                  buttonText="Checkout"
                />
              </div>
            </div>
          </div>
        </div>
        {/* search-Toogler */}
        <div className={`search-box ${searchToogler ? "active" : ""}`}>
          <button
            onClick={() => {
              setSearchToogler(!searchToogler);
            }}
            className={`cart-close ${searchToogler ? "active" : ""}`}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <div className="search-form">
            <form>
              <InputField
                placeholder="Search Heare..."
                type="text"
                name="search"
              />
              <button className="mysearch" type="button">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>
            </form>
            <div className="pro-search-result-area">
              <div className="search-rasult-header">
                <h6>15 result found</h6>
                <Link to="/shop">Shop View</Link>
              </div>
              <HeaderSearch
                white={white}
                star={faStar}
                herfStar={faStarHalfStroke}
                shopingbag={faBagShopping}
                heart={faHeart}
                protitle="Cotton Jersey T-Shirt"
                rating="50+"
                views="Reviews"
                newprise="$18.00"
                oldprise="$39.99"
                alt="white"
              />
              <HeaderSearch
                white={blue}
                star={faStar}
                herfStar={faStarHalfStroke}
                shopingbag={faBagShopping}
                heart={faHeart}
                protitle="Shirt In Botanical Cheetah Print"
                rating="50+"
                views="Reviews"
                newprise="$8.00"
                oldprise="$29.99"
                alt="white"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default HeaderRight;
