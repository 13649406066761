import { faCircleUser, faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faBagShopping,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import logo1 from "../../assets/logo/logo1.png";
import HeaderRight from "../headerright/HeaderRight";
import HeaderTop from "../headertop/HeaderTop";
import Menu from "../menu/Menu";
import "./Header1.css";
const Header1 = () => {
  return (
    <Fragment>
      <div className="home1-header">
        {/* header-top */}
        <HeaderTop />
        {/* header1 */}
        <div className={`header1-area bg-color ovr-plr`}>
          <div className="container-fluid gx-0">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-2 col-md-3 col-4">
                <div className="logo">
                  <Link to="/">
                    <img src={logo1} alt="logo1" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-7 d-none d-lg-block ">
                <Menu badge={true} />
              </div>
              <div className="col-lg-3 d-none d-lg-block">
                <HeaderRight
                  shoping={faBagShopping}
                  search={faMagnifyingGlass}
                  user={faCircleUser}
                  heart={faHeart}
                />
              </div>
              <div className="col-md-9 col-8 d-block d-lg-none">
                <HeaderRight
                  shoping={faBagShopping}
                  search={faMagnifyingGlass}
                  user={faCircleUser}
                  heart={faHeart}
                />
                <Menu badge={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Header1;
