import { Fragment, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Preloder from "./components/preloder/Preloder";
import ScrollButton from "./components/scrollbutton/ScrollButton";
import Layout1 from "./layout/Layout1";
import Layout2 from "./layout/Layout2";
import About from "./pages/about/About";
import Blog from "./pages/blog/Blog";
import BlogDetails from "./pages/blogdetails/BlogDetails";
import Cart from "./pages/cart/Cart";
import Checkout from "./pages/checkout/Checkout";
import Contact from "./pages/contact/Contact";
import Faq from "./pages/faq/Faq";
import Home from "./pages/home/Home";
import Home2 from "./pages/home2/Home2";
import Shop from "./pages/shop/Shop";
import ShopDetails from "./pages/shopdetails/ShopDetails";
import Wishlist from "./pages/wishlist/Wishlist";
function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  });
  return (
    <div className="App">
      {isLoading ? (
        <Preloder />
      ) : (
        <Fragment>
          <Routes>
            <Route path="/" element={<Layout1 />}>
              <Route index element={<Home />} />
              <Route path="/home2/*" element={<Home2 />} />
              <Route path="/about/*" element={<About />} />
              <Route path="/shop/*" element={<Shop />} />
              <Route path="/blog/*" element={<Blog />} />
              <Route path="/blogdetails/*" element={<BlogDetails />} />
              <Route path="/shopdetails/*" element={<ShopDetails />} />
              <Route path="/wishlist/*" element={<Wishlist />} />
              <Route path="/checkout/*" element={<Checkout />} />
              <Route path="/cart/*" element={<Cart />} />
              <Route path="/faq/*" element={<Faq />} />
              <Route path="/contact/*" element={<Contact />} />
            </Route>
            <Route path="/home2" element={<Layout2 />}>
              <Route index element={<Home2 />} />
            </Route>
          </Routes>
          <ScrollButton />
        </Fragment>
      )}
    </div>
  );
}
export default App;
