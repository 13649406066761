import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Quantity from "../quantity/Quantity";
import "./ProductCartList.css";
const ProductCartList = (props) => {
  return (
    <Fragment>
      <tr>
        <th scope="row">
          <div className="cart-listimg">
            <img src={props.img} alt="img" /> <span>{props.pdname}</span>
          </div>
        </th>
        <td>
          <span className="size">{props.size}</span>
        </td>
        <td>
          {" "}
          <span className="price">{props.price}</span>
        </td>
        {props.stock ? (
          <td>
            <div className="in-stock">
              <span>In Stock</span>
              <Link to={"/cart"}>{props.cartbtn}</Link>
            </div>
          </td>
        ) : (
          ""
        )}
        {props.quantity ? (
          <td className="in-quantity">
            <Quantity className="quantity-details plusmainus-quantity" />
          </td>
        ) : (
          ""
        )}
        {props.total ? (
          <td>
            <span>{props.totalprice}</span>
          </td>
        ) : (
          ""
        )}
        <td>
          {" "}
          <span className="icons">
            <FontAwesomeIcon icon={props.xmark} />
          </span>
        </td>
      </tr>
    </Fragment>
  );
};
export default ProductCartList;
// class="text-[13px] font-medium text-black  whitespace-nowrap px-2 border-b default-border-bottom uppercase"
