import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Quantity from "../quantity/Quantity";
import "./CartToggler.css";

const CartToogler = (props) => {
  return (
    <Fragment>
      <div className="cart-body">
        <ul className="cart-iteam-list">
          <li
            className={`cart-item ${props.classNames ? props.classNames : ""}`}
          >
            <div className="item-img">
              <img src={props.cartimg} alt={props.alt} />
              <button className="close-btn">
                <FontAwesomeIcon icon={props.xmark} />
              </button>
            </div>
            <div className="iteam-content">
              <div className="product-rating">
                <span className="icon">
                  <FontAwesomeIcon icon={props.star} />
                  <FontAwesomeIcon icon={props.star} />
                  <FontAwesomeIcon icon={props.star} />
                  <FontAwesomeIcon icon={props.star1} />
                  <FontAwesomeIcon icon={props.star} />
                </span>
                <span>{props.count}</span>
              </div>
              <h3 className="item-title">
                <Link to="#">{props.mandress}</Link>
              </h3>
              <div className="item-prise">{props.prise1}</div>
              <Quantity className="plusmainus-quantity" />
            </div>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default CartToogler;
