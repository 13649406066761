import { Fragment, default as React } from "react";
import "./InputFild.css";

const InputField = (props) => {
  return (
    <Fragment>
      <div className={`single-input ${props.className ? props.className : ""}`}>
        <input
          placeholder={props.placeholder}
          type={props.type}
          name={props.name}
          id={props.id}
        />
        {props.laeblname ? (
          <label htmlFor={props.for}>{props.label}</label>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};
export default InputField;
