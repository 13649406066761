import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./Cetagorisv1.css";
const Cetagorisv1 = (props) => {
  return (
    <Fragment>
      <div
        className={`single-cetagoris-v1 ${
          props.className ? props.className : ""
        }`}
      >
        <div className="cetagoris-title-v1">
          <h5>{props.title}</h5>
          <span>{props.content}</span>
        </div>
        <div className="cetagoris-v1">
          <img src={props.img} alt="cetagoris" />
          {props.link ? <Link to={"#"}>{props.linktext}</Link> : ""}
        </div>
      </div>
    </Fragment>
  );
};
export default Cetagorisv1;
