import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, default as React } from "react";
import "./Button.css";
const Button = (props) => {
  const className = props.className ? props.className : "";
  return (
    <Fragment>
      <button
        className={`button ${className}`}
        type={props.type !== undefined ? props.type : "submit"}
        onClick={props.onclickCallback}
      >
        {props.buttonText}
        {props.icons ? <FontAwesomeIcon icon={props.icons} /> : ""}
      </button>
    </Fragment>
  );
};
export default Button;
