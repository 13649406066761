import React, { Fragment, useState } from "react";
import backgrounds from "../../assets/newsletter/newsletter1.png";
import newsletter2 from "../../assets/newsletter/newsletter2.png";
import Button from "../../components/button/Button";
import HeroSectionv2 from "../../components/herosectionv2/HeroSectionv2";
import InputField from "../../components/inputfild/InputFild";
import NewsLetter from "../../components/newsletter/NewsLetter";
import breadcrumb from "../../assets/hero/breadcrumb.jpg";
import "./Checkout.css";
const Checkout = () => {
  const [discountes, setDiscountes] = useState(false);
  const handleDiscount = () => {
    setDiscountes(!discountes);
  };
  return (
    <Fragment>
      {/* herosectionv2-area-start */}
      <HeroSectionv2 title="Checkout" homelink="Home" breadcrumb={breadcrumb} pagename="Checkout" />
      {/* herosectionv2-area-end */}
      {/* coupon-area-start */}
      <div className="coupon-area section-ptb">
        <div className="container">
          <div className="row">
            <div className="container">
              <div className="col-12">
                <div className="coupon">
                  <span className="coupon-do">Have a coupon?</span>
                  <span onClick={handleDiscount} className="coupon-code">
                    Click here to enter your code
                  </span>
                </div>
                <div
                  className={`discount-from discountes ${
                    discountes ? "active" : ""
                  }`}
                >
                  <form>
                    <InputField
                      placeholder="Discount Code"
                      type="text"
                      name="discount"
                      className="discounts"
                    />
                    <Button
                      buttonText="Apply"
                      type="button"
                      className="discounts-btn"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* coupon-area-end */}
      {/* billing-details-order-summary-start */}
      <div className="billing-summary-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="billing-details-wraper">
                <div className="billing-form">
                  <h2>Billing Details</h2>
                  <form>
                    <div className="row">
                      <div className="col-lg-6 mb-15">
                        <InputField
                          type="text"
                          placeholder="First Name*"
                          name="fname"
                          className="billing-input"
                        />
                      </div>
                      <div className="col-lg-6 mb-15">
                        <InputField
                          type="text"
                          placeholder="Last Name*"
                          name="lname"
                          className="billing-input"
                        />
                      </div>
                      <div className="col-lg-6 mb-15">
                        <InputField
                          type="text"
                          placeholder="Email Address*"
                          name="email"
                          className="billing-input"
                        />
                      </div>
                      <div className="col-lg-6 mb-15">
                        <InputField
                          type="text"
                          placeholder="Phone Number*"
                          name="number"
                          className="billing-input"
                        />
                      </div>
                      <div className="col-lg-12 mb-15">
                        <div className="your-chose">
                          <div className="fild-name">
                            <span>Country*</span>
                            <select className="country" id="mobile-shop">
                              <option className="select1">
                                United Kingdom
                              </option>
                              <option>japan</option>
                              <option>India</option>
                              <option>Bangladesh</option>
                              <option>Pakistan</option>
                              <option>China</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-15">
                        <div className="address">
                          <InputField
                            type="text"
                            placeholder="Address*"
                            name="address"
                            className="billing-input"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-15">
                        <div className="your-chose">
                          <div className="fild-name">
                            <span> Town / City*</span>
                            <select className="country" id="city">
                              <option className="select1">Manhattan</option>
                              <option>Edison</option>
                              <option>Newark</option>
                              <option>Elizabet</option>
                              <option>Stamford</option>
                              <option>Wayne</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-15">
                        <InputField
                          type="text"
                          placeholder="Postcode / ZIP*"
                          name="Postcode"
                          className="billing-input"
                        />
                      </div>
                      <div className="col-lg-12">
                        <div className="checkbox-create">
                          <InputField
                            type="checkbox"
                            name="checkbox"
                            className="billing-input-checkbox"
                            label="Create an account?"
                            laeblname={true}
                            id="five"
                            for="five"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="checkbox-create shipping-checkbox">
                          <h2>Shipping Address</h2>
                          <InputField
                            type="checkbox"
                            name="checkbox"
                            className="billing-input-checkbox"
                            label="Ship to a different address"
                            laeblname={true}
                            id="six"
                            for="six"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="order-summary-wraper">
                <h2>Your Order</h2>
                <div className="order-summary-enset">
                  <div className="total-inner-pd">
                    <div className="single-order-list">
                      <div className="product-list-title">
                        <span>Product</span>
                        <span>total</span>
                      </div>
                      <div className="order-list">
                        <ul>
                          <li>
                            <span>Commodo Lamp x1</span>
                            <span>X + 4</span>
                            <span>$120</span>
                          </li>
                          <li>
                            <span>Commodo Lamp x1</span>
                            <span>XL + 2</span>
                            <span>$280</span>
                          </li>
                          <li>
                            <span>Commodo Lamp x1</span>
                            <span>XL + 1</span>
                            <span>$260</span>
                          </li>
                          <li>
                            <span>Commodo Lamp x1</span>
                            <span>M + 1</span>
                            <span>$410</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="single-order-list subtotal-list">
                      <div className="product-list-title">
                        <span>Subtotal</span>
                        <span>$670.00</span>
                      </div>
                      <div className="order-list">
                        <ul>
                          <li>
                            <span>Free</span>
                            <span>+$00.00</span>
                          </li>
                          <li>
                            <span>Free drown</span>
                            <span>+$10.00</span>
                          </li>
                          <li>
                            <span>Primary Delivery</span>
                            <span>+$20.00</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="total-order-price">
                      <span>Total</span>
                      <span className="order-rt">$700.00</span>
                    </div>
                  </div>
                  <div className="delivery-product">
                    <div className="payment-refarence">
                      <InputField
                        type="radio"
                        name="price"
                        className="radio-btn cash-on-single"
                        id="thre"
                        for="thre"
                        laeblname={true}
                        label="Direct Bank Transfer"
                      />
                      <div className="refarence-text">
                        <p>Make your payment directly into our bank account. Please use your Order ID as the payment reference. 
                            Your order will not be shipped until the funds have cleared in our account.</p>
                      </div>
                    </div>
                    <div className="cash-on">
                      <InputField
                        type="radio"
                        name="price"
                        className="radio-btn cash-on-single"
                        laeblname={true}
                        label="Cash on Delivery"
                        id="one"
                        for="one"
                      />
                      <InputField
                        type="radio"
                        name="price"
                        className="radio-btn cash-on-single"
                        laeblname={true}
                        label="Credit/Debit Cards or Paypal"
                        id="two"
                        for="two"
                      />
                      <div className="order-btn checkout">
                        <button type="submit">Order Now</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* billing-details-order-summary-end */}
      {/* Our-Newsletter-area-start */}
      <div className="newsletter-area pt-70">
        <NewsLetter
          background={backgrounds}
          smalltitle="Our Newsletter"
          titles="Get weekly update. Sign up and get up to"
          parsent="20% off"
          title="your first purchase"
          img={newsletter2}
        />
      </div>
      {/* Our-Newsletter-area-end */}
    </Fragment>
  );
};
export default Checkout;
