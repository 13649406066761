import { faCircleUser, faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faBagShopping,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/logo.png";
import HeaderRight from "../headerright/HeaderRight";
import Menu from "../menu/Menu";
import "./Header.css";
const Header = () => {
  return (
    <Fragment>
      <div className="header-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-2 col-lg-2 col-md-3 col-4">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 d-none d-lg-block">
              <Menu />
            </div>
            <div className="col-xl-4 col-lg-3 d-none d-lg-block">
              <HeaderRight
                shoping={faBagShopping}
                search={faMagnifyingGlass}
                user={faCircleUser}
                heart={faHeart}
              />
            </div>
            {/* resposive-col */}
            <div className="col-md-9 col-8 d-block d-lg-none">
              <HeaderRight
                shoping={faBagShopping}
                search={faMagnifyingGlass}
                user={faCircleUser}
                heart={faHeart}
              />
              <Menu />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Header;
