import {
  faArrowsUpDownLeftRight,
  faHeart,
  faRotate,
  faStar,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import fewture1 from "../../assets/fewture-product/fewture1.png";
import fewture2 from "../../assets/fewture-product/fewture2.png";
import fewture5 from "../../assets/fewture-product/fewture5.png";
import fewture6 from "../../assets/fewture-product/fewture6.png";
import fewture7 from "../../assets/fewture-product/fewture7.png";
import fewture8 from "../../assets/fewture-product/fewture8.png";
import fewture9 from "../../assets/fewture-product/fewture9.png";
import fewture10 from "../../assets/fewture-product/fewture10.png";
import shirt2 from "../../assets/fewture-product/shirt2.png";
import cort2 from "../../assets/fewture-product/cort2.png";
import woman from "../../assets/fewture-product/woman.png";
import woman2 from "../../assets/fewture-product/woman2.png";
import switter from "../../assets/fewture-product/switter.png";
import switter2 from "../../assets/fewture-product/switter2.png";
import ads from "../../assets/all-shop/ads.png";
import adslogo from "../../assets/all-shop/adslogo.png";
import cort from "../../assets/all-shop/cort.png";
import shapeads from "../../assets/all-shop/shapeads.png";
import shapeads1 from "../../assets/all-shop/shapeads1.png";
import shirt from "../../assets/all-shop/shirt.png";
import backgrounds from "../../assets/newsletter/newsletter1.png";
import newsletter2 from "../../assets/newsletter/newsletter2.png";
import Button from "../../components/button/Button";
import HeroSectionv2 from "../../components/herosectionv2/HeroSectionv2";
import InputField from "../../components/inputfild/InputFild";
import NewsLetter from "../../components/newsletter/NewsLetter";
import Pagenation from "../../components/pagenation/Pagenation";
import ProductCard from "../../components/productcard/ProductCard";
import RangeSlider from "../../components/rangeslider/RangeSlider";
import breadcrumb from "../../assets/hero/breadcrumb.jpg";
import "./Shop.css";
const Shop = () => {
  const navigate = useNavigate();
  const [close, setClose] = useState(false);
  const handleXmark = () => {
    setClose(!close);
  };

  return (
    <Fragment>
      {/* herosectionv2-area-start */}
      <HeroSectionv2 title="Shop" homelink="Home" breadcrumb={breadcrumb} pagename="Shop" />
      {/* herosectionv2-area-end */}
      {/* shop-page-start */}
      <div className="shop-page-area pt-70">
        <div className="container">
          <div className="row">
            <div className="col-md-12 d-md-block d-lg-none">
            <div className="prodict-shoting">
              <div className="shoting-left">
                <p>
                  Showing <span>1-12 of 84 results</span>
                </p>
              </div>
              <div className="shoting-right">
                <div className="shot-default">
                  <select id="mobile-shop">
                    <option className="select1">Shop by Latest</option>
                    <option>Shop by Oldest</option>
                    <option>Shop by Name</option>
                    <option>Shop by Price</option>
                  </select>
                </div>
              </div>
            </div>
            </div>
            <div className="col-lg-4 col-xl-3">
              <div className="filter-start">
                <div className="filter-widget mb-20">
                  <div className="subject-title">
                    <h2>Categories</h2>
                  </div>
                  <div className="filter-items">
                    <ul>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Women’s Tshirt"
                            className="filter"
                            laeblname={true}
                            id="label1"
                            for="label1"
                          />
                          <span>59</span>
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Women Accessories"
                            className="filter"
                            laeblname={true}
                            id="label2"
                            for="label2"
                          />
                          <span>18</span>
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="collage Bag"
                            className="filter"
                            laeblname={true}
                            id="label3"
                            for="label3"
                          />
                          <span>12</span>
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Man Cap"
                            className="filter"
                            laeblname={true}
                            id="label4"
                            for="label4"
                          />
                          <span>67</span>
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Old Man Dress"
                            className="filter"
                            laeblname={true}
                            id="label5"
                            for="label5"
                          />
                          <span>10</span>
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Lahenga"
                            className="filter"
                            laeblname={true}
                            id="label6"
                            for="label6"
                          />
                          <span>56</span>
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Fashion Everyone"
                            className="filter"
                            laeblname={true}
                            id="label7"
                            for="label7"
                          />
                          <span>11</span>
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Night Care"
                            className="filter"
                            laeblname={true}
                            id="label8"
                            for="label8"
                          />
                          <span>15</span>
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="chare bag"
                            className="filter"
                            laeblname={true}
                            id="label9"
                            for="label9"
                          />
                          <span>54</span>
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Baby Items"
                            className="filter"
                            laeblname={true}
                            id="label10"
                            for="label10"
                          />
                          <span>41</span>
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Eye Care"
                            className="filter"
                            laeblname={true}
                            id="label11"
                            for="label11"
                          />
                          <span>08</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="price-filter mb-20">
                  <h2>Price Filter</h2>
                  <RangeSlider />
                </div>
                <div className="filter-widget widget-1 mb-20">
                  <div className="subject-title">
                    <h2>Brands</h2>
                  </div>
                  <div className="filter-items">
                    <ul>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Nike"
                            className="filter"
                            laeblname={true}
                            id="labela"
                            for="labela"
                          />
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Zara"
                            className="filter"
                            laeblname={true}
                            id="labelb"
                            for="labelb"
                          />
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Denim"
                            className="filter"
                            laeblname={true}
                            id="labelc"
                            for="labelc"
                          />
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Madame"
                            className="filter"
                            laeblname={true}
                            id="labeld"
                            for="labeld"
                          />
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Arong"
                            className="filter"
                            laeblname={true}
                            id="labele"
                            for="labele"
                          />
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Max mall"
                            className="filter"
                            laeblname={true}
                            id="labelf"
                            for="labelf"
                          />
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Sara"
                            className="filter"
                            laeblname={true}
                            id="labelg"
                            for="labelg"
                          />
                        </div>
                      </li>
                      <li>
                        <div className="single-filter">
                          <InputField
                            type="checkbox"
                            name="mobile"
                            label="Hanger"
                            className="filter"
                            laeblname={true}
                            id="label1h"
                            for="label1h"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="color-mixer filter-widget widget-1 price-filter mb-20">
                  <h2>Colours</h2>
                  <ul>
                    <li>
                      <span className="color color-one"></span>
                    </li>
                    <li>
                      <span className="color color-two"></span>
                    </li>
                    <li>
                      {" "}
                      <span className="color color-three"></span>
                    </li>
                    <li>
                      {" "}
                      <span className="color color-four"></span>
                    </li>
                    <li>
                      <span className="color color-five"></span>
                    </li>
                    <li>
                      <span className="color color-six"></span>
                    </li>
                  </ul>
                </div>
                <div className="tag filter-widget widget-1 price-filter mb-20" >
                  <h2>Size</h2>
                  <ul>
                    <li>
                      <Link to={"#"}>S</Link>
                    </li>
                    <li>
                      <Link to={"#"}>M</Link>
                    </li>
                    <li>
                      <Link to={"#"}>L</Link>
                    </li>
                    <li>
                      <Link to={"#"}>XL</Link>
                    </li>
                    <li>
                      <Link to={"#"}>2XL</Link>
                    </li>
                    <li>
                      <Link to={"#"}>X</Link>
                    </li>
                    <li>
                      <Link to={"/"}>XXL</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Slim Fit</Link>
                    </li>
                    <li>
                      <Link to={"#"}>XS</Link>
                    </li>
                  </ul>
                </div>
                <div className={`ads close ${close ? "active" : ""}`}>
                  <div className="ads-inner">
                    <div className="adslogo">
                      <img src={adslogo} alt="adslogo" />
                    </div>
                    <div onClick={handleXmark} className={"xmark"}>
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                    <Button
                      buttonText="Shop Now"
                      className="button shop-btn blog-btn"
                      onclickCallback={() => {
                        navigate("/shopdetails");
                      }}
                    />
                    <div className="adsimg">
                      <img src={ads} alt="ads" />
                    </div>
                    <div className="shape-ads">
                      <img src={shapeads} alt="shapeads" />
                    </div>
                    <div className="shape-ads1">
                      <img src={shapeads1} alt="shapeads1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-xl-9 ">
              <div className="d-none d-lg-block">
                <div className="prodict-shoting">
                  <div className="shoting-left">
                    <p>
                      Showing <span>1-12 of 84 results</span>
                    </p>
                  </div>
                  <div className="shoting-right">
                    <div className="shot-default">
                      <select>
                        <option className="select1">Shop by Latest</option>
                        <option>Shop by Oldest</option>
                        <option>Shop by Name</option>
                        <option>Shop by Price</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6 mb-20">
                <ProductCard
                  img={fewture1}
                  img2={fewture2}
                  btn="Add To Cart"
                  review={faStar}
                  title="Woman Dress"
                  newrate="$190"
                  oldrate="$210"
                  discount="New"
                  discountes={true}
                  heart={faHeart}
                  equal={faArrowsUpDownLeftRight}
                  rotate={faRotate}
                  className="single-shop fewtureproduct-pd fewtureproduct-default"
                  sp="(23)"
                  classimg="second-product"
                  classimg2="first-product"
                  classNam="section-bg-v1 shop-section"
                  classNames="discount new-dis"
                  classNamfew="content-bg"
                  others="shop-link"
                />
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6  mb-20">
                <ProductCard
                  img={shirt2}
                  img2={shirt}
                  btn="Add To Cart"
                  review={faStar}
                  title="Man Shirt"
                  newrate="$185"
                  oldrate="$205"
                  discount="Sale"
                  discountes={true}
                  classNames="discount Sale"
                  heart={faHeart}
                  equal={faArrowsUpDownLeftRight}
                  rotate={faRotate}
                  className="single-shop fewtureproduct-pd fewtureproduct-default1"
                  sp="(0)"
                  classimg="second-product"
                  classimg2="first-product"
                  classNam="section-bg-v1 shop-section"
                  classNamfew="content-bg"
                  rating="rating"
                  others="shop-link"
                />
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6  mb-20">
                <ProductCard
                  img={cort}
                  img2={cort2}
                  btn="Add To Cart"
                  review={faStar}
                  title="Man Cort"
                  newrate="$175"
                  oldrate="$194"
                  discount="Sale"
                  discountes={true}
                  classNames="discount Sale"
                  heart={faHeart}
                  equal={faArrowsUpDownLeftRight}
                  rotate={faRotate}
                  className="single-shop fewtureproduct-pd fewtureproduct-default2"
                  sp="(88)"
                  classimg="second-product"
                  classimg2="first-product"
                  classNam="section-bg-v1 shop-section"
                  classNamfew="content-bg"
                  others="shop-link"
                />
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6  mb-20">
                <ProductCard
                  img={woman}
                  img2={woman2}
                  btn="Add To Cart"
                  review={faStar}
                  title="Woman Fashion"
                  newrate="$180"
                  oldrate="$220"
                  discount="- 25%"
                  discountes={true}
                  heart={faHeart}
                  equal={faArrowsUpDownLeftRight}
                  rotate={faRotate}
                  className="single-shop fewtureproduct-pd"
                  sp="(26)"
                  classimg="second-product"
                  classimg2="first-product"
                  classNam="section-bg-v1 shop-section"
                  classNames="discount"
                  classNamfew="content-bg"
                  others="shop-link"
                />
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6  mb-20">
                <ProductCard
                  img={switter}
                  img2={switter2}
                  btn="Add To Cart"
                  review={faStar}
                  title="Sweitter"
                  newrate="$320"
                  oldrate="$400"
                  discount="Sale"
                  discountes={true}
                  classNames="discount Sale"
                  heart={faHeart}
                  equal={faArrowsUpDownLeftRight}
                  rotate={faRotate}
                  className="single-shop fewtureproduct-pd fewtureproduct-default2"
                  sp="(0)"
                  classimg="second-product"
                  classimg2="first-product"
                  classNam="section-bg-v1 shop-section"
                  classNamfew="content-bg"
                  rating="rating"
                  others="shop-link"
                />
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6  mb-20">
                <ProductCard
                  img={fewture5}
                  img2={fewture6}
                  btn="Add To Cart"
                  review={faStar}
                  title="comfort Farneture"
                  newrate="$150"
                  discount="New"
                  discountes={true}
                  heart={faHeart}
                  equal={faArrowsUpDownLeftRight}
                  rotate={faRotate}
                  className="single-shop fewtureproduct-pd fewtureproduct-default1"
                  sp="(76)"
                  classimg="second-product"
                  classimg2="first-product"
                  classNam="section-bg-v1 shop-section"
                  classNames="discount new-dis"
                  classNamfew="content-bg"
                  others="shop-link"
                />
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6  mb-20">
                <ProductCard
                  img={fewture7}
                  img2={fewture8}
                  btn="Add To Cart"
                  review={faStar}
                  title="Man Fashion"
                  newrate="$120"
                  oldrate="$180"
                  discount="Sale"
                  discountes={true}
                  classNames="discount Sale"
                  heart={faHeart}
                  equal={faArrowsUpDownLeftRight}
                  rotate={faRotate}
                  className="single-shop fewtureproduct-pd fewtureproduct-default"
                  sp="(89)"
                  classimg="second-product"
                  classimg2="first-product"
                  classNam="section-bg-v1 shop-section"
                  classNamfew="content-bg"
                  others="shop-link"
                />
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 mb-20">
                <ProductCard
                  img={fewture9}
                  img2={fewture10}
                  btn="Add To Cart"
                  review={faStar}
                  title="Woman Bag"
                  newrate="$150"
                  oldrate="$200"
                  discount="New"
                  discountes={true}
                  heart={faHeart}
                  equal={faArrowsUpDownLeftRight}
                  rotate={faRotate}
                  className="single-shop fewtureproduct-pd fewtureproduct-default1"
                  sp="76)"
                  classimg="second-product"
                  classimg2="first-product"
                  classNam="section-bg-v1 shop-section"
                  classNames="discount new-dis"
                  classNamfew="content-bg"
                  others="shop-link"
                />
                </div>
                <div className="col-xl-4 d-lg-none d-xl-block d-md-block col-md-6  mb-20">
                <ProductCard
                  img={switter}
                  img2={switter2}
                  btn="Add To Cart"
                  review={faStar}
                  title="Switter"
                  newrate="$150"
                  oldrate="$220"
                  discount="New"
                  discountes={true}
                  heart={faHeart}
                  equal={faArrowsUpDownLeftRight}
                  rotate={faRotate}
                  className="single-shop fewtureproduct-pd fewtureproduct-default1"
                  sp="(34)"
                  classimg="second-product"
                  classimg2="first-product"
                  classNam="section-bg-v1 shop-section"
                  classNames="discount new-dis"
                  classNamfew="content-bg"
                  others="shop-link"
                />
                </div>
              </div>
              <div className="shop-pagenation">
                <Pagenation />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* shop-page-end */}
      {/* Our-Newsletter-area-start */}
      <div className="newsletter-area pt-70">
        <NewsLetter
          background={backgrounds}
          smalltitle="Our Newsletter"
          titles="Get weekly update. Sign up and get up to"
          parsent="20% off"
          title="your first purchase"
          img={newsletter2}
        />
      </div>
      {/* Our-Newsletter-area-end */}
    </Fragment>
  );
};
export default Shop;
