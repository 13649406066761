import {
  faFacebookF,
  faPinterest,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faHeart,faEye } from "@fortawesome/free-regular-svg-icons";
import { faRotate, faStar,faCubes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../button/Button";
import Quantity from "../quantity/Quantity";
import "./EmbroideredRight.css";
const EmbroideredRight = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="embroidered-right-wraper">
        <div className="embroidered-content">
          <h2>Slick Woman Dress</h2>
          <div className="product-price">
            <div className="old-price">
              <del>$130</del>
            </div>
            <div className="new-price">
              <span>$50</span>
            </div>
          </div>
          <div className="item-rating">
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span className="review">4 Reviews</span>
          </div>
          <div className="user-view">
            <p>
              In ornare lorem ut est dapibus, ut tincidunt nisi pretium. Integer ante est, hendrerit
              in rutrum quis, elementum eget magna. Pellentesque sagittis dictum libero, eu dignissim tellus.
            </p>
            <span>
            <FontAwesomeIcon icon={faEye} /> 56 people are viewing this Product
            </span>
            <span className="strock">
            <FontAwesomeIcon icon={faCubes} /> In stock
            </span>
          </div>
          <div className="color-chose color-mixer filter-widget widget-1 price-filter">
            <h2>Color</h2>
            <ul>
              <li>
                <span className="color color-one"></span>
              </li>
              <li>
                <span className="color color-two"></span>
              </li>
              <li>
                {" "}
                <span className="color color-three"></span>
              </li>
              <li>
                {" "}
                <span className="color color-four"></span>
              </li>
              <li>
                <span className="color color-five"></span>
              </li>
              <li>
                <span className="color color-six"></span>
              </li>
            </ul>
          </div>
          <div className="product-size">
            <h2>Size</h2>
            <ul>
              <li>
                <Link to={"#"}>M</Link>
              </li>
              <li>
                <Link to={"#"}>L</Link>
              </li>
              <li>
                <Link to={"#"}>XL</Link>
              </li>
              <li>
                <Link to={"#"}>X</Link>
              </li>
            </ul>
          </div>
          <div className="item-addtocart">
            <div className="item-quantity">
              <Quantity className="quantity-details" />
            </div>
            <div className="details-button">
              <Button
                buttonText="Add To Cart"
                className="quantity-btn"
                onclickCallback={() => {
                  navigate("/cart");
                }}
              />
            </div>
            <div className="reaction">
              <span>
                <FontAwesomeIcon icon={faRotate} />
              </span>
              <span>
                <FontAwesomeIcon icon={faHeart} />
              </span>
            </div>
          </div>
          <div className="category-item">
            <p>
              SKU : <span>KE-91039</span>
            </p>
            <p>
              Category :<span> Kitchen </span>
            </p>
            <p>
              Tags : <span>Beer, Foamer</span>
            </p>
          </div>
          <div className="item-share">
            <h2>Share</h2>
            <span className="fb">
              <Link to={"#"}>
                <FontAwesomeIcon icon={faFacebookF} />
              </Link>
            </span>
            <span className="tw">
              <Link to={"#"}>
                <FontAwesomeIcon icon={faTwitter} />
              </Link>
            </span>
            <span className="ps">
              <Link to={"#"}>
                <FontAwesomeIcon icon={faPinterest} />
              </Link>
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default EmbroideredRight;
