import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../node_modules/swiper/swiper-bundle.min.css";
import casualman from "../../assets/deal-product/casual-man-dress.jpg";
import fashionwoman from "../../assets/deal-product/fashion-woman-top.jpg";
import shorttshirt from "../../assets/deal-product/short-sleeve-tshirt.jpg";
import DealProduct from "../dealproduct/DealProduct";
import "./DealProductSlider.css";
const DealProductSlider = () => {
  return (
    <Fragment>
      <div className={"deal-product-slider"}>
        <Swiper
          spaceBetween={15}
          loop={true}
          navigation={{
            prevEl: ".button-prev-slide",
            nextEl: ".button-next-slide",
          }}
          modules={[Navigation]}
          breakpoints={{
            576: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            991: {
              slidesPerView: 2,
            },
            1400: {
              slidesPerView: 3,
            },
          }}
        >
          <SwiperSlide>
            <DealProduct
              img={casualman}
              link="Shop Now"
              title="Product Short Name"
              parsent="20%"
              off="Off"
              offerend="Hurry Up! Limited Time Offer End"
            />
          </SwiperSlide>
          <SwiperSlide>
            <DealProduct
              img={fashionwoman}
              link="Shop Now"
              title="Product Short Name"
              parsent="25%"
              off="Off"
              offerend="Hurry Up! Limited Time Offer End"
            />
          </SwiperSlide>
          <SwiperSlide>
            <DealProduct
              img={shorttshirt}
              link="Shop Now"
              title="Product Short Name"
              parsent="30%"
              off="Off"
              offerend="Hurry Up! Limited Time Offer End"
            />
          </SwiperSlide>
          <SwiperSlide>
            <DealProduct
              img={casualman}
              link="Shop Now"
              title="Product Short Name"
              parsent="20%"
              off="Off"
              offerend="Hurry Up! Limited Time Offer End"
            />
          </SwiperSlide>

          <SwiperSlide>
            <DealProduct
              img={casualman}
              link="Shop Now"
              title="Product Short Name"
              parsent="20%"
              off="Off"
              offerend="Hurry Up! Limited Time Offer End"
            />
          </SwiperSlide>
          <SwiperSlide>
            <DealProduct
              img={fashionwoman}
              link="Shop Now"
              title="Product Short Name"
              parsent="25%"
              off="Off"
              offerend="Hurry Up! Limited Time Offer End"
            />
          </SwiperSlide>
          <SwiperSlide>
            <DealProduct
              img={shorttshirt}
              link="Shop Now"
              title="Product Short Name"
              parsent="30%"
              off="Off"
              offerend="Hurry Up! Limited Time Offer End"
            />
          </SwiperSlide>
          <SwiperSlide>
            <DealProduct
              img={casualman}
              link="Shop Now"
              title="Product Short Name"
              parsent="20%"
              off="Off"
              offerend="Hurry Up! Limited Time Offer End"
            />
          </SwiperSlide>
        </Swiper>
        <div className="deal-next-prev">
          <div className="button-next-slide">
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
          <div className="button-prev-slide">
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DealProductSlider;
