import React, { Fragment, useEffect, useState } from "react";
import "./Countdown.css";
const Countdown = (props) => {
  //  countdown-start
  const countdownDate = new Date(props.date).getTime();

  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const time = () => {
    setNewTime();
  };
  useEffect(() => {
    const intervel = setInterval(time, 1000);
    return () => {
      clearInterval(intervel);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewTime = () => {
    if (countdownDate) {
      const currentTime = new Date().getTime();

      const distanceToDate = countdownDate - currentTime;

      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

      days = `${days}`;
      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      } else if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      } else if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }

      setState({ days: days, hours: hours, minutes, seconds });
    }
  };
  return (
    <Fragment>
      <div
        className={`deal-product-timer ${
          props.className ? props.className : ""
        }`}
      >
        <div className="single-timer">
          {props.text ? <span>DAYS</span> : ""}
          <span className="time">{state.days || "0"}</span>
          {props.text1 ? <span>DAYS</span> : ""}
        </div>
        <div className="single-timer">
          {props.text ? <span>HRS</span> : ""}
          <span className="time">{state.hours || "00"}</span>
          {props.text1 ? <span>HRS</span> : ""}
        </div>
        <div className="single-timer">
          {props.text ? <span>MIN</span> : ""}
          <span className="time">{state.minutes || "00"}</span>
          {props.text1 ? <span>MIN</span> : ""}
        </div>
        <div className="single-timer">
          {props.text ? <span>SEC</span> : ""}
          <span className="time">{state.seconds || "00"}</span>
          {props.text1 ? <span>SEC</span> : ""}
        </div>
      </div>
    </Fragment>
  );
};
export default Countdown;
