import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faArrowLeft,
  faArrowRight,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import team1 from "../../assets/team/team1.png";
import team2 from "../../assets/team/team2.png";
import team3 from "../../assets/team/team3.png";
import team4 from "../../assets/team/team4.png";
import TeamCard from "../teamcard/TeamCard";
import "./OurTeamSlider.css";
const OurTeamSlider = () => {
  return (
    <Fragment>
      <div className="our-team-slider">
        <Swiper
          spaceBetween={20}
          loop={true}
          navigation={{
            prevEl: ".button-prev-slide",
            nextEl: ".button-next-slide",
          }}
          modules={[Navigation]}
          breakpoints={{
            576: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            991: {
              slidesPerView: 3,
            },
            1400: {
              slidesPerView: 4,
            },
          }}
        >
          <SwiperSlide>
            <TeamCard
              img={team4}
              name="rosalina Rhodes"
              position="CEO of oshop"
              farebook={faFacebookF}
              linkdin={faLinkedinIn}
              twitter={faTwitter}
              plus={faPlus}
              mainus={faMinus}
            />
          </SwiperSlide>
          <SwiperSlide>
            <TeamCard
              img={team2}
              name="Abubokkor A K"
              position="Alonso Ux Designer"
              farebook={faFacebookF}
              linkdin={faLinkedinIn}
              twitter={faTwitter}
              plus={faPlus}
              mainus={faMinus}
            />
          </SwiperSlide>
          <SwiperSlide>
            <TeamCard
              img={team3}
              name="Dynasic"
              position="Marketing Expert"
              farebook={faFacebookF}
              linkdin={faLinkedinIn}
              twitter={faTwitter}
              plus={faPlus}
              mainus={faMinus}
            />
          </SwiperSlide>
          <SwiperSlide>
            <TeamCard
              img={team1}
              name="Davidthy Phillips"
              position="Senior Developer"
              farebook={faFacebookF}
              linkdin={faLinkedinIn}
              twitter={faTwitter}
              plus={faPlus}
              mainus={faMinus}
            />
          </SwiperSlide>
          <SwiperSlide>
            <TeamCard
              img={team4}
              name="Deniella Rhodes"
              position="CEO of Etoshi"
              farebook={faFacebookF}
              linkdin={faLinkedinIn}
              twitter={faTwitter}
              plus={faPlus}
              mainus={faMinus}
            />
          </SwiperSlide>
          <SwiperSlide>
            <TeamCard
              img={team4}
              name="Deniella Rhodes"
              position="CEO of Etoshi"
              farebook={faFacebookF}
              linkdin={faLinkedinIn}
              twitter={faTwitter}
              plus={faPlus}
              mainus={faMinus}
            />
          </SwiperSlide>
          <SwiperSlide>
            <TeamCard
              img={team2}
              name="Abubokkor A K"
              position="UiUx Designer"
              farebook={faFacebookF}
              linkdin={faLinkedinIn}
              twitter={faTwitter}
              plus={faPlus}
              mainus={faMinus}
            />
          </SwiperSlide>
          <SwiperSlide>
            <TeamCard
              img={team3}
              name="Wensleydale"
              position=" Marketing Expert"
              farebook={faFacebookF}
              linkdin={faLinkedinIn}
              twitter={faTwitter}
              plus={faPlus}
              mainus={faMinus}
            />
          </SwiperSlide>
          <SwiperSlide>
            <TeamCard
              img={team1}
              name="Davidthy Phillips"
              position="Senior Developer"
              farebook={faFacebookF}
              linkdin={faLinkedinIn}
              twitter={faTwitter}
              plus={faPlus}
              mainus={faMinus}
            />
          </SwiperSlide>
          <SwiperSlide>
            <TeamCard
              img={team4}
              name="Deniella Rhodes"
              position="CEO of Etoshi"
              farebook={faFacebookF}
              linkdin={faLinkedinIn}
              twitter={faTwitter}
              plus={faPlus}
              mainus={faMinus}
            />
          </SwiperSlide>
        </Swiper>
        <div className="our-team-next-prev">
          <div className="button-next-slide">
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
          <div className="button-prev-slide">
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default OurTeamSlider;
