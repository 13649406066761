import {
  faArrowsUpDownLeftRight,
  faHeart,
  faRotate,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import dealweakleft from "../../assets/deal-product/deal-weak-right-bg.png";
import dealweak from "../../assets/deal-product/deal-weak.png";
import backgrounds from "../../assets/newsletter/newsletter1.png";
import newsletter2 from "../../assets/newsletter/newsletter2.png";
import vector1 from "../../assets/promo/Vector-1.png";
import vector2 from "../../assets/promo/Vector-2.png";
import vector3 from "../../assets/promo/Vector-3.png";
import shapimg4 from "../../assets/promo/promo-img-4.png";
import shapimg5 from "../../assets/promo/promo-img-5.png";
import shapimg6 from "../../assets/promo/promo-img-6.png";
import shape from "../../assets/cetagoris/shape.png";
import fewture1 from "../../assets/fewture-product/fewture1.png";
import fewture2 from "../../assets/fewture-product/fewture2.png";
import fewture5 from "../../assets/fewture-product/fewture5.png";
import fewture6 from "../../assets/fewture-product/fewture6.png";
import fewture7 from "../../assets/fewture-product/fewture7.png";
import fewture8 from "../../assets/fewture-product/fewture8.png";
import fewture9 from "../../assets/fewture-product/fewture9.png";
import fewture10 from "../../assets/fewture-product/fewture10.png";
import shirt from "../../assets/fewture-product/shirt.png";
import shirt2 from "../../assets/fewture-product/shirt2.png";
import cort from "../../assets/fewture-product/cort.png";
import cort2 from "../../assets/fewture-product/cort2.png";
import woman from "../../assets/fewture-product/woman.png";
import woman2 from "../../assets/fewture-product/woman2.png";
import switter from "../../assets/fewture-product/switter.png";
import switter2 from "../../assets/fewture-product/switter2.png";
import support from "../../assets/work-processing/proces6.svg";
import search from "../../assets/work-processing/dolar.svg";
import dron from "../../assets/work-processing/dron2.svg";
import proces3 from "../../assets/work-processing/proces3.svg";
import Button from "../../components/button/Button";
import Cetagorisv1 from "../../components/cetagorisv1/Cetagorisv1";
import CetagorySliderv1 from "../../components/cetagorysliderv1/CetagorySliderv1";
import Countdown from "../../components/countdown/Countdown";
import FewtureProdectSlide from "../../components/fewtureproductslide/FewtureProdectSlide";
import HeroSectionv1 from "../../components/herosectionv1/HeroSectionv1";
import NewsLetter from "../../components/newsletter/NewsLetter";
import ProductCard from "../../components/productcard/ProductCard";
import PromoCardv1 from "../../components/promocardv1/PromoCardv1";
import SectionTitle from "../../components/sectiontitle/SectionTitle";
import TestimonialSlide1 from "../../components/testimonialslide1/TestimonialSlide1";
import testimonialbg from "../../assets/testimonial/testimonial-bg.jpg"
import "./Home.css";
const Home = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      {/* hero-sectionv1-start */}
      <HeroSectionv1 />
      {/* hero-sectionv1-end */}
      {/* categories-area-start */}
      <div className="categoris-v1-area pt-70">
        <div className="container">
          <div className="total-cetagoris">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  minititle="Top Categories"
                  title="Chose by Top Category"
                  className="mb-20"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <CetagorySliderv1 />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* categories-area-end */}
      {/* promotional-area-start */}
      <div className="promotional-v1-area pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-20">
              {/* single-promo */}
              <PromoCardv1
                promoimg1={shapimg6}
                title="Super Sale"
                bigtitle="woman Fashion"
                link="Shop Now"
                promoshape={vector1}
                className="promotion-v1"
                upto="Up to"
                discountv1="17%"
                discountsofv1="Off"
                classNames="discountv1"
                cardbg="cardbg1"
                promoimg2={shape}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              {/* single-promo */}
              <PromoCardv1
                className="promosection-v1-default shape2 default"
                promoimg1={shapimg5}
                title="T-shirts Collection"
                bigtitle="Man T-shirt"
                link="Shop Now"
                promoshape={vector2}
                upto="Up to"
                discountv1="23%"
                discountsofv1="Off"
                classNames="discountv1"
                bottomimg="bottom-img"
                cardbg="cardbg2"
                badgebg="badgebg"
                promoimg2={shape}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              {/* single-promo */}
              <PromoCardv1
                promoimg1={shapimg4}
                title="Shoe Collection"
                bigtitle="Man shoes"
                link="Shop Now"
                promoshape={vector3}
                upto="Up to"
                discountv1="89%"
                discountsofv1="Off"
                classNames="discountv1"
                className="shape2"
                cardbg="cardbg3"
                promoimg2={shape}
              />
            </div>
          </div>
        </div>
      </div>
      {/* promotional-area-end */}
      {/* FewtureProduct-area-start */}
      <div className="FewtureProduct-area section-bg-v1 pt-70">
        <div className="container">
          <div className="row">
            <SectionTitle
              minititle="Features Product"
              title="Our Features Collection"
              className="mb-20"
            />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <FewtureProdectSlide />
            </div>
          </div>
        </div>
      </div>
      {/* FewtureProduct-area-end */}
    
      {/* work-processing-area-start */}
      <div className="work-procesing-area pt-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                minititle="How To Working"
                title="How it Work processing"
                className="mb-20 text-center"
              />
            </div>
            <div className="col-lg-4 col-xl-3 col-md-6 mb-20">
              <Cetagorisv1
                img={search}
                title="Search Iteam"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit ac "
                link={true}
                linktext="Step 1"
                className="work-procesing imgbg-1"
              />
            </div>
            <div className="col-lg-4 col-xl-3 col-md-6 mb-20">
              <Cetagorisv1
                img={support}
                title="24/7 Support"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit ac "
                link={true}
                linktext="Step 2"
                className="work-procesing imgbg-2"
              />
            </div>
            <div className="col-lg-4 col-xl-3 col-md-6 mb-20">
              <Cetagorisv1
                img={dron}
                title="First Dalevary"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit ac "
                link={true}
                linktext="Step 3"
                className="work-procesing imgbg-3"
              />
            </div>
            <div className="d-lg-none d-xl-block col-xl-3 col-md-6 mb-20">
              <Cetagorisv1
                img={proces3}
                title="Enjoy the Product"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit ac "
                link={true}
                linktext="Step 4"
                className="work-procesing imgbg-4"
              />
            </div>
          </div>
        </div>
      </div>
      {/* work-processing-area-end */}
      {/* deal-of-weak-area-start */}
      <div className="deal-of-weak-area pt-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7 col-md-7 pr-0 pr-12">
              <div
                className="deal-weak-bg1"
                style={{ backgroundImage: `url(${dealweakleft})` }}
              >
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-8 col-7">
                    <SectionTitle
                      minititle="Deal of the weak"
                      title="Deal of the weak Let's Shopping Today"
                      className="mb-20"
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-5">
                    <div className="deal-of">
                      <div className="deal-weak-sheap">
                        <span>Discount</span>
                        <h5>50%</h5>
                        <span>Off</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="deal-weak-countdown">
                  <span className="offer">Hurry Up! Offer In Close</span>
                  <Countdown
                    date={"12/25/2024"}
                    text1={true}
                    className="deal-countdown"
                  />
                  <Button
                    buttonText="Shop Now"
                    onclickCallback={() => {
                      navigate("/shop");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-5 pl-0 pl-12">
                <div className="deal-weak-img">
                  <img src={dealweak} alt="dealweak" />
                </div>
            </div>
          </div>
        </div>
      </div>
      {/* deal-of-weak-area-end */}
      {/* all-product-shop-area-start */}
      <div className="shop-section pt-70 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <SectionTitle
                minititle="All Product"
                title="Our Store Collection"
                className="mb-20"
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <div
                className="nav nav-tabs product-tabs"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active"
                  id="nav-best-sales"
                  data-bs-toggle="tab"
                  data-bs-target="#best-sales"
                  type="button"
                  role="tab"
                  aria-controls="best-sales"
                  aria-selected="true"
                >
                  Best Sellers
                </button>
                <button
                  className="nav-link"
                  id="nav-new-product"
                  data-bs-toggle="tab"
                  data-bs-target="#new-product"
                  type="button"
                  role="tab"
                  aria-controls="new-product"
                  aria-selected="false"
                >
                  New Products
                </button>
                <button
                  className="nav-link"
                  id="nav-sale-product"
                  data-bs-toggle="tab"
                  data-bs-target="#sale-product"
                  type="button"
                  role="tab"
                  aria-controls="sale-product"
                  aria-selected="false"
                >
                  Sale Products
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="best-sales"
                  role="tabpanel"
                  aria-labelledby="nav-best-sales"
                  tabIndex="0"
                >
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={fewture1}
                        img2={fewture2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Woman Fashion"
                        newrate="$120"
                        discount="New"
                        discountes={true}
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default"
                        sp="(23)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="section-bg-v1 shop-section"
                        classNames="discount new-dis"
                        classNamfew="content-bg"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={shirt2}
                        img2={shirt}
                        btn="Add To Cart"
                        review={faStar}
                        title="Man Shirt"
                        newrate="$185"
                        oldrate="$205"
                        discount="Sale"
                        discountes={true}
                        classNames="discount Sale"
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default1"
                        sp="(0)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="section-bg-v1 shop-section"
                        classNamfew="content-bg"
                        rating="rating"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                       img={cort}
                       img2={cort2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Fashion Cort"
                        newrate="$175"
                        discount="Sale"
                        discountes={true}
                        classNames="discount Sale"
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default2"
                        sp="(88)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="section-bg-v1 shop-section"
                        classNamfew="content-bg"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={woman}
                        img2={woman2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Woman Dress"
                        newrate="$180"
                        oldrate="$220"
                        discount="- 25%"
                        discountes={true}
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd"
                        sp="(26)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="section-bg-v1 shop-section"
                        classNames="discount"
                        classNamfew="content-bg"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={switter}
                        img2={switter2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Switter"
                        newrate="$320"
                        oldrate="$400"
                        discount="Sale"
                        discountes={true}
                        classNames="discount Sale"
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default2"
                        sp="(0)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="section-bg-v1 shop-section"
                        classNamfew="content-bg"
                        rating="rating"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={fewture5}
                        img2={fewture6}
                        btn="Add To Cart"
                        review={faStar}
                        title="Comfort cheyar"
                        newrate="$150"
                        discount="New"
                        discountes={true}
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default1"
                        sp="(76)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="section-bg-v1 shop-section"
                        classNames="discount new-dis"
                        classNamfew="content-bg"
                      />
                    </div>
                    <div className="d-lg-none col-md-6 d-xxl-block col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={fewture7}
                        img2={fewture8}
                        btn="Add To Cart"
                        review={faStar}
                        title="Fashion"
                        newrate="$120"
                        discount="Sale"
                        discountes={true}
                        classNames="discount Sale"
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default"
                        sp="(89)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="section-bg-v1 shop-section"
                        classNamfew="content-bg"
                      />
                    </div>
                    <div className="d-lg-none col-md-6 d-xxl-block col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={fewture9}
                        img2={fewture10}
                        btn="Add To Cart"
                        review={faStar}
                        title="woman bag"
                        newrate="$150"
                        discount="New"
                        discountes={true}
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default1"
                        sp="76)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="section-bg-v1 shop-section"
                        classNames="discount new-dis"
                        classNamfew="content-bg"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="new-product"
                  role="tabpanel"
                  aria-labelledby="nav-new-product"
                  tabIndex="0"
                >
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={switter}
                        img2={switter2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Switter"
                        newrate="$150"
                        discount="New"
                        discountes={true}
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default1"
                        sp="(34)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="section-bg-v1 shop-section"
                        classNames="discount new-dis"
                        classNamfew="content-bg"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={fewture1}
                        img2={fewture2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Woman Fashion"
                        newrate="$120"
                        discount="New"
                        discountes={true}
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd fewtureproduct-default"
                        sp="(11)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="section-bg-v1 shop-section"
                        classNames="discount new-dis"
                        classNamfew="content-bg"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="sale-product"
                  role="tabpanel"
                  aria-labelledby="nav-sale-product"
                  tabIndex="0"
                >
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={woman}
                        img2={woman2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Woman Dress"
                        newrate="$180"
                        oldrate="$220"
                        discount="Sale"
                        discountes={true}
                        classNames="discount Sale"
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd"
                        sp="(13)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="section-bg-v1 shop-section"
                        classNamfew="content-bg"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xxl-3 mb-20">
                      {/* Tab panes */}
                      <ProductCard
                        img={woman}
                        img2={woman2}
                        btn="Add To Cart"
                        review={faStar}
                        title="Woman Dress"
                        newrate="$180"
                        oldrate="$220"
                        discount="Sale"
                        discountes={true}
                        classNames="discount Sale"
                        heart={faHeart}
                        equal={faArrowsUpDownLeftRight}
                        rotate={faRotate}
                        className="single-shop fewtureproduct-pd"
                        sp="(32)"
                        classimg="second-product"
                        classimg2="first-product"
                        classNam="section-bg-v1 shop-section"
                        classNamfew="content-bg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* all-product-shop-area-end */}
      {/* testimonial-area-start */}
      <div className="testimonial-area section-ptb" style={{ backgroundImage: `url(${testimonialbg})` }}>
        <div className="container">
          <div className="col-12">
            <SectionTitle
              minititle="Testimonial"
              title="What People Are saying "
              className="mb-20 text-center"
            />
          </div>
          <div className="row">
            <div className="offset-lg-3 col-lg-6">
              <div className="testimonial-slide1">
                <TestimonialSlide1 />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* testimonial-area-end */}
      {/* Our-Newsletter-area-start */}
      <div className="newsletter-area pt-70">
        <NewsLetter
          background={backgrounds}
          smalltitle="Newsletter"
          titles="Subscribe to our newsletter and get "
          parsent="20% off"
          title=" your first purchase"
          img={newsletter2}
        />
      </div>
      {/* Our-Newsletter-area-end */}
    </Fragment>
  );
};
export default Home;
