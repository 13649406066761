import hero5 from "../../assets/hero/hero-right-2.jpg"
import hero6 from "../../assets/hero/hero-right-3.jpg"
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "../../../node_modules/swiper/swiper-bundle.min.css";
import Button from "../button/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay,EffectFade,Zoom} from 'swiper/modules';
import "./HeroSectionv1.css";
const HeroSectionv1 = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="hero-slider-area">
        <div className="container-fluid gx-0">
        <div className="row align-items-center">
            <div className="col-lg-12">
              <div className={"hero-slider"}>
              <Swiper
                  loop={true}
                  spaceBetween={0}
                  parallax={true}
                  effect={'fade'}
                  zoom={true}
                  watchSlidesProgress={true}
                  autoplay={{
                    delay: 3500,
                    disableOnInteraction: false
                  }}
                  modules={[Autoplay,EffectFade,Zoom]}
                  breakpoints={{
                    576: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 1,
                    },
                    991: {
                      slidesPerView: 1,
                    },
                    1400: {
                      slidesPerView: 1,
                    },
                  }}
                >
                <SwiperSlide>
                <div className="herosection-v1 hero-bg p-relative">
                  <div className="row align-items-center">
                    <div className="col-lg-7 mb-50">
                      <div className="ovr-plr">
                        <div className="hero-v1-title">
                        <span className="wow fadeInUp" data-wow-duration="1.2s" data-wow-delay=".5s">FASHION STORE IN THIS SEASON</span>
                        <h2>Exclusive Collection <br/> For Woman</h2>
                        <p> Elevate your loungewear game with our carefully curated ensembles,<br/>  combining plush fabrics and contemporary designs.</p>
                        <Button
                            buttonText="Shop Now"
                            onclickCallback={() => {
                              navigate("/shop");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 mb-50 h-100">
                      <div className="right-img">
                        <img src={hero5} alt="hero4" />
                      </div>
                    </div>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="herosection-v1 hero-bg p-relative">
                  <div className="row  align-items-center">
                    <div className="col-lg-7 mb-50">
                      <div className="ovr-plr">
                        <div className="hero-v1-title">
                          <span>FASHION STORE IN LATEST TRENDING</span>
                          <h2>Exclusive Collection <br/> For Man & Woman</h2>
                          <p> Elevate your loungewear game with our carefully curated ensembles,<br/>  combining plush fabrics and contemporary designs.</p>
                          <Button
                              buttonText="Shop Now"
                              onclickCallback={() => {
                                navigate("/shop");
                              }}
                            />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 mb-50 h-100">
                      <div className="right-img">
                        <img src={hero6} alt="hero4" />
                      </div>
                    </div>
                  </div>
                </div>
                </SwiperSlide>
              </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default HeroSectionv1;
