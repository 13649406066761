import React, { Fragment } from "react";
import "./SectionTitle.css";
const SectionTitle = (props) => {
  return (
    <Fragment>
      <div
        className={`section-title ${props.className ? props.className : ""}`}
      >
        <span>{props.minititle}</span>
        <h2>{props.title}</h2>
      </div>
    </Fragment>
  );
};
export default SectionTitle;
