import React, { Fragment } from "react";
import { Oval } from "react-loader-spinner";
import "./Preloder.css";
const Preloder = () => {
  return (
    <Fragment>
      <div className="preloder">
        <Oval
          height={80}
          width={80}
          color="#ff497c"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#f9f3f0"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    </Fragment>
  );
};
export default Preloder;
