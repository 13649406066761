import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./PromoCard.css";
const PromoCard = (props) => {
  return (
    <Fragment>
      <div
        className={`promo-1 ${props.className ? props.className : ""}`}
        style={{ backgroundImage: `url(${props.shap1 ? props.shap1 : ""})` }}
      >
        <div className="mains-img">
          <img src={props.promoimg1} alt="promoimg1" />
        </div>
        <div className="promo-title">
          <h6>{props.title}</h6>
          <h3>{props.bigtitle}</h3>
          <Link className="button-c button2" to={"/shop"}>
            {props.link}
          </Link>
        </div>
        <div
          className="promo-discount"
        >
          <span>
            {props.discount?props.discount:""}
            <br />
            {props.discountsof?props.discountsof:""}
          </span>
        </div>
      </div>
    </Fragment>
  );
};
export default PromoCard;
