import { faXmark } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import cartimg1 from "../../assets/cartlist/1.png";
import cartimg2 from "../../assets/cartlist/2.png";
import cartimg3 from "../../assets/cartlist/3.png";
import cartimg4 from "../../assets/cartlist/4.png";
import backgrounds from "../../assets/newsletter/newsletter1.png";
import newsletter2 from "../../assets/newsletter/newsletter2.png";
import Button from "../../components/button/Button";
import HeroSectionv2 from "../../components/herosectionv2/HeroSectionv2";
import InputField from "../../components/inputfild/InputFild";
import NewsLetter from "../../components/newsletter/NewsLetter";
import ProductCartList from "../../components/productcartlist/ProductCartList";
import breadcrumb from "../../assets/hero/breadcrumb.jpg";
import "./Cart.css";
const Cart = () => {
  return (
    <Fragment>
      {/* herosectionv2-area-start */}
      <HeroSectionv2 title="Cart" homelink="Home" breadcrumb={breadcrumb} pagename="Cart" />
      {/* herosectionv2-area-end */}
      {/* product-cart-list-start */}
      <div className="product-cart-list-area pt-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive table-responsive-md  table-responsive-lg">
                <table className="table">
                  <thead>
                    <tr bgcolor="">
                      <th scope="col">Products</th>
                      <th scope="col">Size</th>
                      <th scope="col">Price</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Total</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ProductCartList
                      img={cartimg2}
                      pdname="Slick Woman Fashion"
                      size="X"
                      price="$120"
                      xmark={faXmark}
                      className="bg-white"
                      quantity={true}
                      totalprice="$280"
                      total={true}
                    />
                    <ProductCartList
                      img={cartimg1}
                      pdname="black Woman Fashion"
                      size="XL"
                      price="$140"
                      xmark={faXmark}
                      quantity={true}
                      totalprice="$250"
                      total={true}
                    />
                    <ProductCartList
                      img={cartimg4}
                      pdname="Red Woman Fashion"
                      size="XL"
                      price="$160"
                      xmark={faXmark}
                      className="bg-white"
                      quantity={true}
                      totalprice="$380"
                      total={true}
                    />
                    <ProductCartList
                      img={cartimg3}
                      pdname="Black Woman Fashion"
                      size="MM"
                      price="$155"
                      xmark={faXmark}
                      quantity={true}
                      totalprice="$180"
                      total={true}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="discount-code">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-5 mb-20">
                <div className="discount-from">
                  <form>
                    <InputField
                      placeholder="Discount Code"
                      type="text"
                      name="discount"
                      className="discounts"
                    />
                    <Button
                      buttonText="Apply"
                      type="button"
                      className="discounts-btn"
                    />
                  </form>
                </div>
              </div>
              <div className="col-lg-8 col-md-7 mb-20">
                <div className="update-cart">
                  <div className="back-shoping">
                    <Link to={"/shop"}>Continue Shopping</Link>
                  </div>
                  <div className="updates-cart back-shoping">
                    <Link to={"#"}>Update Cart</Link>
                  </div>
                </div>
              </div>
              <div className="offset-lg-7 col-lg-5">
                <div className="subtotal-wraper">
                  <div className="subtotal-top">
                    <h3>Subtotal</h3>
                    <h3 className="subtotal-amount">$490.00</h3>
                  </div>
                  <div className="shiping">
                    <h4>Shipping</h4>
                    <ul>
                      <li>
                        <div className="shipping-left">
                          <InputField
                            type="radio"
                            name="price"
                            className="radio-btn billing-input-checkbox"
                            label="Free Shipping"
                            laeblname={true}
                            id="saven"
                            for="saven"
                          />
                        </div>
                        <div className="shipping-right">
                          <span>+$00.00</span>
                        </div>
                      </li>
                      <li>
                        <div className="shipping-left">
                          <InputField
                            type="radio"
                            name="price"
                            className="radio-btn billing-input-checkbox"
                            label="Flat Rate"
                            laeblname={true}
                            id="eight"
                            for="eight"
                          />
                        </div>
                        <div className="shipping-right">
                          <span>+$3.00</span>
                        </div>
                      </li>
                      <li>
                        <div className="shipping-left">
                          <InputField
                            type="radio"
                            name="price"
                            className="radio-btn billing-input-checkbox"
                            label="Local Delivery"
                            laeblname={true}
                            id="nine"
                            for="nine"
                          />
                        </div>
                        <div className="shipping-right">
                          <span>+$40.00</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="shipping-calculate">
                    <h3>Calculate Shipping</h3>
                    <div className="select-country">
                      <select id="mobile-shop">
                        <option className="select1">Select Country</option>
                        <option>japan</option>
                        <option>India</option>
                        <option>Bangladesh</option>
                        <option>Pakistan</option>
                        <option>China</option>
                      </select>
                    </div>
                    <InputField
                      placeholder="Postcode / ZIP"
                      type="text"
                      className="zip"
                    />
                    <Button
                      buttonText="Update Cart"
                      type="button"
                      className="update-btn"
                    />
                  </div>
                  <div className="total-bottom subtotal-top">
                    <h3>Total</h3>
                    <h3 className="subtotal-amount">$230.00</h3>
                  </div>
                  <div className="checkout">
                    <Link className="button-c" to={"/checkout"}>Proceed to Checkout</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* product-cart-list-end */}
      {/* Our-Newsletter-area-start */}
      <div className="newsletter-area pt-70">
        <NewsLetter
          background={backgrounds}
          smalltitle="Our Newsletter"
          titles="Get weekly update. Sign up and get up to"
          parsent="20% off"
          title="your first purchase"
          img={newsletter2}
        />
      </div>
      {/* Our-Newsletter-area-end */}
    </Fragment>
  );
};
export default Cart;
