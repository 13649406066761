import React, { Fragment } from "react";
import Button from "../button/Button";
import InputField from "../inputfild/InputFild";
import "./NewsLetter.css";
const NewsLetter = (props) => {
  return (
    <Fragment>
      <div className="container">
        <div
          className="newsletter-bg"
          style={{ backgroundImage: `url(${props.background})` }}
        >
          <div className="row">
            <div className="col-lg-6 col-xxl-7">
              <div className="newsletter-content newsletter">
                <div className="newsletter-title">
                  <span>{props.smalltitle}</span>
                  <h2>
                    {props.titles}
                    <span>{props.parsent}</span>
                    {props.title}
                  </h2>
                </div>
                <div className="newsletter-form">
                  <form action="#">
                    <InputField
                      placeholder="Enter Your Email"
                      type="text"
                      name="newsletter"
                      className="newsletterinput"
                    />
                    <Button
                      buttonText="Subscribe"
                      className="newsletter-btn"
                      type="button"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xxl-5">
              <div className="newsletter-right-img">
                <img src={props.img} alt="newsletter" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default NewsLetter;
