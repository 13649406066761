import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useState } from "react";
import "./Quantity.css";
const Quantity = (props) => {
  const [count, setCount] = useState(5);
  const increment = () => {
    setCount(function (prevCount) {
      return (prevCount += 1);
    });
  };
  const decrement = () => {
    setCount(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 0);
      }
    });
  };
  return (
    <Fragment>
      <div
        className={`item-quantity ${props.className ? props.className : ""}`}
      >
        <button onClick={increment}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <h3>{count}</h3>
        <button onClick={decrement}>
          <FontAwesomeIcon icon={faMinus} />
        </button>
      </div>
    </Fragment>
  );
};

export default Quantity;
