import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./HeaderTop.css";
const HeaderTop = () => {
  return (
    <Fragment>
      <div className="header-top">
        <div className="ovr-plr">
          <div className="container-fluid gx-0">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="header-info">
                  <ul>
                    <li>
                      <span>Email:</span>
                      <Link to={"#"}> support@info.com</Link>
                    </li>
                    <li>
                      <p>
                        Today`s: Discount <span> Off 50%</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className=" header-info-right">
                  <div className="header-info select-wrap order-tracking">
                    <ul>
                      <li>
                        <Link to={"#"}>Order Tracking </Link>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="select-wrap order-tracking">
                    <ul>
                      <li>
                        <span>
                          Currency <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                        <ul>
                          <li>
                            <Link to={"#"}>EUR - Euro</Link>
                          </li>
                          <li>
                            <Link to={"#"}>USD - US Dollar</Link>
                          </li>
                          <li>
                            <Link to={"#"}> British Pound</Link>
                          </li>
                          <li>
                            <Link to={"#"}>INR - Indian</Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="select-wrap langguage">
                    <ul>
                      <li>
                        <span>
                          Language <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                        <ul>
                          <li>
                            <Link to={"#"}>Armenian</Link>
                          </li>
                          <li>
                            <Link to={"#"}>Bambara</Link>
                          </li>
                          <li>
                            <Link to={"#"}>Bangla</Link>
                          </li>
                          <li>
                            <Link to={"#"}>Aymara</Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default HeaderTop;
