import { faQuoteLeft, faStar } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import author from "../../assets/testimonial/author.png";
import Testimonial from "../testimonial/Testimonial";
import "./TestimonialSlide.css";
const TestimonialSlide = () => {
  return (
    <Fragment>
      <Swiper
        spaceBetween={15}
        loop={true}
        pagination={{ clickable: true }}
        modules={[Pagination]}
        breakpoints={{
          576: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          991: {
            slidesPerView: 1,
          },
          1400: {
            slidesPerView: 1,
          },
        }}
      >
        <SwiperSlide>
          <Testimonial
            icon={faQuoteLeft}
            star={faStar}
            content="Don't miss out on this exclusive deal! Time is running out to take advantage
             of our special offer. Act fast to secure incredible savings before it's too late! Grab
            this opportunity now before it expires!"
            authorname="Ronal M. Griffim"
            position="Business Manager"
            img={author}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            icon={faQuoteLeft}
            star={faStar}
            content="Don't miss out on this exclusive deal! Time is running out to take advantage
            of our special offer. Act fast to secure incredible savings before it's too late! Grab
            this opportunity now before it expires!"
            authorname="Ronal M. Griffim"
            position="Business Manager"
            img={author}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            icon={faQuoteLeft}
            star={faStar}
            content="Don't miss out on this exclusive deal! Time is running out to take advantage
            of our special offer. Act fast to secure incredible savings before it's too late! Grab
            this opportunity now before it expires!"
            authorname="Ronal M. Griffim"
            position="Business Manager"
            img={author}
          />
        </SwiperSlide>
      </Swiper>
    </Fragment>
  );
};
export default TestimonialSlide;
