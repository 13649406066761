import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Shape from "../../assets/hero/heroshape.png";
import leftimg from "../../assets/hero/herov2-left.png";
import rightimg from "../../assets/hero/herov2-right.png";
import "./HeroSectionv2.css";
const HeroSectionv2 = (props) => {
  return (
    <Fragment>
      <div className="hero-breadcrumb-area" style={{ backgroundImage: `url(${props.breadcrumb})` }}>
        <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-4 col-4">
              <div className="herosectionv2-img">
                <div className="shape">
                  <img src={Shape} alt="Shape" />
                </div>
                <div className="mains-img">
                  <img src={leftimg} alt="leftimg" />
                </div>
              </div>
          </div>
          <div className="col-lg-4 col-md-4 col-4">
            <div className="herosectionv2-content text-center">
              <h3>{props.title}</h3>
              <ul>
                <li>
                  <Link to={"/"}>{props.homelink}</Link>
                </li>
                <li>{props.pagename}</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-4">
              <div className="herosectionv2-img float-right">
                <div className="shape">
                  <img src={Shape} alt="Shape" />
                </div>
                <div className="mains-img">
                  <img src={rightimg} alt="rightimg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default HeroSectionv2;
