import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./Pagenation.css";
const Pagenation = () => {
  return (
    <Fragment>
      <div className="pagenation">
        <ul>
          <li>
            <Link to={"/"}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </Link>
          </li>
          <li>
            <Link to={"/"}>01</Link>
          </li>
          <li>
            <Link to={"/"}>02</Link>
          </li>
          <li>
            <Link to={"/"}>03</Link>
          </li>
          <li>
            <Link to={"/"}>
              <span>...</span>
            </Link>
          </li>
          <li>
            <Link to={"/"}>20</Link>
          </li>
          <li>
            <Link className="active" to={"/"}>
              <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default Pagenation;
