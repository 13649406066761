import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import customer1 from "../../assets/all-shop/customer1.png";
import customer2 from "../../assets/all-shop/customer2.png";
import "./SingleReview.css";
const SingleReview = (props) => {
  return (
    <Fragment>
      <div className="single-review">
        <h2>{props.title}</h2>
        <div className="inner-pd">
          <div className="customer-wraper">
            <div className="customer-details">
              <div className="customer-img">
                <img src={customer1} alt="customer1" />
              </div>
              <div className="customer-names">
                <h4>Cameron Williamson</h4>
                <span>feb 14, 2024</span>
                <div className="customer-rating">
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span className="grade">(5.0)</span>
                </div>
              </div>
            </div>
            <div className="customer-reply">
              <Link to={"#"}>Reply</Link>
            </div>
          </div>

          <div className="customer-content customer-single-pb">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsium has been the industry's standards dummy as
              text as when an unknown printer took asi galley of type and
              scrambled it to make a type specimen book.
            </p>
          </div>
          <div className="ml-45">
            <div className="customer-wraper">
              <div className="customer-details">
                <div className="customer-img">
                  <img src={customer2} alt="customer2" />
                </div>
                <div className="customer-names">
                  <h4>Bablu Khan</h4>
                  <span>feb 14, 2024</span>
                  <div className="customer-rating">
                    <span>
                      <FontAwesomeIcon icon={faStar} />
                    </span>
                    <span>
                      <FontAwesomeIcon icon={faStar} />
                    </span>
                    <span>
                      <FontAwesomeIcon icon={faStar} />
                    </span>
                    <span>
                      <FontAwesomeIcon icon={faStar} />
                    </span>
                    <span>
                      <FontAwesomeIcon icon={faStar} />
                    </span>
                    <span className="grade">(5.0)</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="customer-content">
              <p>
                Lorem Ipsum is simply dumm text of the printing and typesetting
                industry.
              </p>
            </div>
          </div>
        </div>
        <div className="inner-pd mt-20">
          <div className="customer-wraper">
            <div className="customer-details">
              <div className="customer-img">
                <img src={customer1} alt="customer3" />
              </div>
              <div className="customer-names">
                <h4>Hosain Jhon</h4>
                <span>feb 14, 2024</span>
                <div className="customer-rating">
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span className="grade">(5.0)</span>
                </div>
              </div>
            </div>
            <div className="customer-reply">
              <Link to={"#"}>Reply</Link>
            </div>
          </div>

          <div className="customer-content">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsium has been the industry's standards dummy as
              text as when an unknown printer took asi galley of type and
              scrambled it to make a type specimen book.
            </p>
          </div>
        </div>
        <div className="inner-pd mt-20">
          <div className="customer-wraper">
            <div className="customer-details">
              <div className="customer-img">
                <img src={customer2} alt="customer4" />
              </div>
              <div className="customer-names">
                <h4>Nihal Hal</h4>
                <span>feb 14, 2024</span>
                <div className="customer-rating">
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span className="grade">(5.0)</span>
                </div>
              </div>
            </div>
            <div className="customer-reply">
              <Link to={"#"}>Reply</Link>
            </div>
          </div>

          <div className="customer-content">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsium has been the industry's standards dummy as
              text as when an unknown printer took asi galley of type and
              scrambled it to make a type specimen book.
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default SingleReview;
