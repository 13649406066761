import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Countdown from "../countdown/Countdown";
import "./DealProduct.css";
const DealProduct = (props) => {
  return (
    <Fragment>
      <div className="deal-product-single">
        <div className="deal-product-left">
          <div className="deal-product-img">
            <img src={props.img} alt="img" />
            <Link className="deal-product-btn" to={"/shop"}>
              {props.link}
            </Link>
          </div>
        </div>
        <div className="deal-product-right">
          <h5>{props.title}</h5>
          <div className="persent">
            <span>{props.parsent}</span>
            <h5>{props.off}</h5>
          </div>
          <Countdown date={"12/25/2025"} text={true} />
          <div className="offer-end">
            <span>{props.offerend}</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DealProduct;
