import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import TextAreaFiled from "../../components/textareafild/TextAreaFild";
import Button from "../button/Button";
import InputField from "../inputfild/InputFild";
import SingleReview from "../singlereview/SingleReview";
import "./Discription.css";
const Discription = () => {
  return (
    <Fragment>
      <div className="discription-reviews-tabs">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="home-tab-pane"
                    aria-selected="true"
                  >
                    Description
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="profile-tab-pane"
                    aria-selected="false"
                  >
                    Reviews
                  </button>
                </li>
              </ul>
              <div
                className="tab-content discription-tablist"
                id="myTabContent"
              >
                <div
                  className="tab-pane fade show active"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabIndex="0"
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="discription">
                        <h2>Description</h2>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsium has been the
                          industry's standard dummy as text everthe 1500s, when
                          an unknown printer our atook a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries bualso the on leap
                          into electronic typesetting, remaining essentially
                          unchanged. Its wasn’t popularised in the 1960s
                          witogbcxh the release of Letraset sheets containing
                          Lorem Ipsum passages, andei more recently with desktop
                          publishing software like Axzldus PageMaker a including
                          versions of LIpsum to make a type specimen book.which
                          looks reasonable. The generated Lorem Ipsum is
                          therefore always free from repetition which looks
                          reasonable. The generated as Lorem Ipsum is therefore
                          always free from repetition which looks reasonable.
                          The generated Lorem Ipsum is therefore always free
                          from repetition
                        </p>
                        <h4>Features :</h4>
                        <ul>
                          <li>Chic and Stylish</li>
                          <li>Practical and Weather-Ready</li>
                          <li> Featuring Remove Before Flight Ribbon Keychain</li>
                          <li>Sleeve Pocket with Remove Before Flight Ribbon Keychain</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile-tab-pane"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  tabIndex="0"
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="review">
                        <SingleReview title="Reviews ( 04 )" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 pt-70">
                    <div className="reviews-form">
                      <h2>Write Your Reviews</h2>
                      <div className="form-rating-reviews">
                        <span>
                          <FontAwesomeIcon icon={faStar} />
                        </span>
                        <span>
                          <FontAwesomeIcon icon={faStar} />
                        </span>
                        <span>
                          <FontAwesomeIcon icon={faStar} />
                        </span>
                        <span>
                          <FontAwesomeIcon icon={faStar} />
                        </span>
                        <span>
                          <FontAwesomeIcon icon={faStar} />
                        </span>
                        <span className="review">(0.0)</span>
                        <div className="review-form-here">
                          <form>
                            <div className="row">
                              <div className="col-lg-4 mb-20">
                                <InputField
                                  placeholder="Name*"
                                  type="text"
                                  name="name"
                                  className="review-input"
                                />
                              </div>
                              <div className="col-lg-4 mb-20">
                                <InputField
                                  placeholder="Email**"
                                  type="text"
                                  name="email"
                                  className="review-input"
                                />
                              </div>
                              <div className="col-lg-4 mb-20">
                                <InputField
                                  placeholder="Phone Number*"
                                  type="text"
                                  name="phonenumber"
                                  className="review-input"
                                />
                              </div>
                              <div className="col-lg-12">
                                <TextAreaFiled
                                  placeholder="Your Message"
                                  name="textarea"
                                  className="review-textarea"
                                />
                                <Button
                                  buttonText="Submit Review"
                                  type="button"
                                  className="reviews-btn"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Discription;
