import {
  faArrowLeft,
  faArrowRight,
  faArrowsUpDownLeftRight,
  faHeart,
  faRotate,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../node_modules/swiper/swiper-bundle.min.css";
import fewture1 from "../../assets/fewture-product/fewture1.png";
import fewture2 from "../../assets/fewture-product/fewture2.png";
import shirt from "../../assets/fewture-product/shirt.png";
import shirt2 from "../../assets/fewture-product/shirt2.png";
import cort from "../../assets/fewture-product/cort.png";
import cort2 from "../../assets/fewture-product/cort2.png";
import woman from "../../assets/fewture-product/woman.png";
import woman2 from "../../assets/fewture-product/woman2.png";
import switter from "../../assets/fewture-product/switter.png";
import switter2 from "../../assets/fewture-product/switter2.png";
import ProductCard from "../productcard/ProductCard";
import "./FewtureProdectSlide.css";
const FewtureProdectSlide = () => {
  return (
    <Fragment>
      <div className={"fewture-product-slider"}>
        <Swiper
          loop={true}
          spaceBetween={15}
          navigation={{
            prevEl: ".button-prev-slide3",
            nextEl: ".button-next-slide3",
          }}
          modules={[Navigation]}
          breakpoints={{
            576: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            991: {
              slidesPerView: 3,
            },
            1400: {
              slidesPerView: 4,
            },
          }}
        >
        <SwiperSlide>
          <ProductCard
            img={fewture1}
            img2={fewture2}
            btn="Add To Cart"
            review={faStar}
            title="Woman-Fashion"
            newrate="$180"
            oldrate="$220"
            discount="- 40%"
            heart={faHeart}
            equal={faArrowsUpDownLeftRight}
            rotate={faRotate}
            className="fewtureproduct-default"
            classimg="second-product"
            classimg2="first-product"
            sp="(40)"
            classNames="discount"
          />
        </SwiperSlide>
          <SwiperSlide>
            <ProductCard
              img={shirt2}
              img2={shirt}
              btn="Add To Cart"
              review={faStar}
              title="Man-shirt"
              newrate="$180"
              oldrate="$220"
              discount="- 40%"
              heart={faHeart}
              equal={faArrowsUpDownLeftRight}
              rotate={faRotate}
              className="fewtureproduct-default"
              classimg="second-product"
              classimg2="first-product"
              sp="(40)"
              classNames="discount"
            />
          </SwiperSlide>
          <SwiperSlide>
            <ProductCard
              img={cort}
              img2={cort2}
              btn="Add To Cart"
              review={faStar}
              title="Black cort"
              newrate="$180"
              oldrate="$220"
              discount="- 40%"
              heart={faHeart}
              equal={faArrowsUpDownLeftRight}
              rotate={faRotate}
              className="fewtureproduct-default"
              classimg="second-product"
              classimg2="first-product"
              sp="(40)"
              classNames="discount"
            />
          </SwiperSlide>
          <SwiperSlide>
            <ProductCard
              img={woman}
              img2={woman2}
              btn="Add To Cart"
              review={faStar}
              title="Women Dress"
              newrate="$180"
              oldrate="$220"
              discount="- 40%"
              heart={faHeart}
              equal={faArrowsUpDownLeftRight}
              rotate={faRotate}
              className="fewtureproduct-default"
              classimg="second-product"
              classimg2="first-product"
              sp="(40)"
              classNames="discount"
            />
          </SwiperSlide>
          <SwiperSlide>
            <ProductCard
              img={switter}
              img2={switter2}
              btn="Add To Cart"
              review={faStar}
              title="Switter"
              newrate="$180"
              oldrate="$220"
              discount="- 40%"
              heart={faHeart}
              equal={faArrowsUpDownLeftRight}
              rotate={faRotate}
              className="fewtureproduct-default"
              classimg="second-product"
              classimg2="first-product"
              sp="(40)"
              classNames="discount"
            />
          </SwiperSlide>
          <SwiperSlide>
            <ProductCard
              img={woman}
              img2={woman2}
              btn="Add To Cart"
              review={faStar}
              title="Women Dress"
              newrate="$180"
              oldrate="$220"
              discount="- 40%"
              heart={faHeart}
              equal={faArrowsUpDownLeftRight}
              rotate={faRotate}
              className="fewtureproduct-default"
              classimg="second-product"
              classimg2="first-product"
              sp="(40)"
              classNames="discount"
            />
          </SwiperSlide>
          <SwiperSlide>
            <ProductCard
              img={woman}
              img2={woman2}
              btn="Add To Cart"
              review={faStar}
              title="Women Dress"
              newrate="$180"
              oldrate="$220"
              discount="- 40%"
              heart={faHeart}
              equal={faArrowsUpDownLeftRight}
              rotate={faRotate}
              className="fewtureproduct-default"
              classimg="second-product"
              classimg2="first-product"
              sp="(40)"
              classNames="discount"
            />
          </SwiperSlide>
          <SwiperSlide>
            <ProductCard
              img={switter}
              img2={switter2}
              btn="Add To Cart"
              review={faStar}
              title="Switter"
              newrate="$180"
              oldrate="$220"
              discount="- 40%"
              heart={faHeart}
              equal={faArrowsUpDownLeftRight}
              rotate={faRotate}
              className="Switter"
              classimg="second-product"
              classimg2="first-product"
              sp="(40)"
              classNames="discount"
            />
          </SwiperSlide>
          <SwiperSlide>
            <ProductCard
              img={woman}
              img2={woman2}
              btn="Add To Cart"
              review={faStar}
              title="Women Dress"
              newrate="$180"
              oldrate="$220"
              discount="- 40%"
              heart={faHeart}
              equal={faArrowsUpDownLeftRight}
              rotate={faRotate}
              className="fewtureproduct-default"
              classimg="second-product"
              classimg2="first-product"
              sp="(40)"
              classNames="discount"
            />
          </SwiperSlide>
        </Swiper>
        <div className="fewture-next-prev">
          <div className="button-next-slide3">
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
          <div className="button-prev-slide3">
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default FewtureProdectSlide;
