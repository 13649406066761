import { Fragment, default as React, useState } from "react";
import {
  faSquareCheck
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.css";
import iconcom from "../../assets/about/Icon-com.png";
import signature from "../../assets/about/Signature.png";
import aboutcompany from "../../assets/about/about-company.png";
import ceo from "../../assets/about/ceo.png";
import video from "../../assets/about/video.png";
import Retuns from "../../assets/fewture/returns.png";
import security from "../../assets/fewture/security.png";
import shiping from "../../assets/fewture/shipping.png";
import support from "../../assets/fewture/support.png";
import backgrounds from "../../assets/newsletter/newsletter1.png";
import newsletter2 from "../../assets/newsletter/newsletter2.png";
import support2 from "../../assets/work-processing/proces6.svg";
import search from "../../assets/work-processing/dolar.svg";
import dron from "../../assets/work-processing/dron2.svg";
import proces3 from "../../assets/work-processing/proces3.svg";
import Cetagorisv1 from "../../components/cetagorisv1/Cetagorisv1";
import Fewture from "../../components/features/Feature";
import HeroSectionv2 from "../../components/herosectionv2/HeroSectionv2";
import NewsLetter from "../../components/newsletter/NewsLetter";
import OurTeamSlider from "../../components/ourteamslider/OurTeamSlider";
import SectionTitle from "../../components/sectiontitle/SectionTitle";
import TestimonialSlide1 from "../../components/testimonialslide1/TestimonialSlide1";
import breadcrumb from "../../assets/hero/breadcrumb.jpg";
import testimonialbg from "../../assets/testimonial/testimonial-bg.jpg";
import "./About.css";
const About = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Fragment>
      {/* hero-section-v2-start */}
      <HeroSectionv2 title="About Us" homelink="Home" breadcrumb={breadcrumb} pagename="About Us" />
      {/* hero-section-v2-end */}
      {/* about-company-area-start */}
      <div className="about-company-area section-ptb">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="company-img">
                <img
                  className="company"
                  src={aboutcompany}
                  alt="aboutcompany"
                />
                <div className="expreance-company">
                  <img src={iconcom} alt="" />
                  <h6>
                    32 <span>Years Experience</span>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="company-content">
                <SectionTitle
                  minititle="About Store"
                  title="nline shopping includes both buying things online."
                  className="mb-20"
                />
                <p>
                   Empower your sales teams with industry tailored solutions that
                   support manufacturers as they go digital, and adapt to changing 
                   markets & customers faster by creating new business.
                </p>
                <div className="video-area">
                  <div className="video">
                    <ModalVideo
                      channel="youtube"
                      youtube={{ mute: 0, autoplay: 0 }}
                      isOpen={isOpen}
                      videoId="L61p2uyiMSo"
                      onClose={() => setOpen(false)}
                    />
                    <button className="click" onClick={() => setOpen(true)}>
                      <img src={video} alt="video" />
                    </button>
                  </div>
                  <div className="video-content">
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={faSquareCheck} />
                        We provide 100% best products
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faSquareCheck} />
                        Trying to Satisfied customers
                      </li>
                      <li>
                       <FontAwesomeIcon icon={faSquareCheck} />
                        best provide services
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="about-author">
                  <div className="author-img">
                    <img src={ceo} alt="ceo" />
                    <div className="author-info">
                      <h4>HR Harun</h4>
                      <span>CEO of oshop</span>
                    </div>
                  </div>
                  <div className="auhor-signature">
                    <img src={signature} alt="signature" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* about-company-area-end */}
      {/* fewture-area-start */}
      <div className="fewture-area">
        <div className="container">
          <div className="fewture-heare">
            <div className="row">
              <div className="col-lg-3 col-md-6 mb-20">
                <Fewture
                  shiping={shiping}
                  title="Free Shipping"
                  contents="Free Shipping On All US Or Order Above $100"
                />
              </div>
              <div className="col-lg-3 col-md-6 mb-20">
                <Fewture
                  shiping={security}
                  title="Security Payment"
                  contents="Simply Return It Within 30 Days For An Exchange."
                />
              </div>
              <div className="col-lg-3 col-md-6 mb-20">
                <Fewture
                  shiping={Retuns}
                  title="14-Day Return"
                  contents="Simply Return It Within 30 Days For An Exchange."
                />
              </div>
              <div className="col-lg-3 col-md-6 mb-20">
                <Fewture
                  shiping={support}
                  title="24/7 Support"
                  contents="Contact Us 24 Hours A Day, 7 Day's Week"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* fewture-area-end */}
      {/* our-team-area-start */}
      <div className="our-team pt-70">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SectionTitle
                minititle="Our Team"
                title="Expert Management Team"
                className="mb-50"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <OurTeamSlider />
            </div>
          </div>
        </div>
      </div>
      {/* our-team-area-end */}
      {/* testimonial-area-start */}
      <div className="testimonial-area section-ptb" style={{ backgroundImage: `url(${testimonialbg})` }}>
        <div className="container">
          <div className="col-12">
            <SectionTitle
              minititle="Testimonial"
              title="What People Are saying "
              className="mb-20 text-center"
            />
          </div>
          <div className="row">
            <div className="offset-lg-3 col-lg-6">
              <div className="testimonial-slide1">
                <TestimonialSlide1 />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* testimonial-area-end */}
      {/* work-processing-area-start */}
      <div className="work-procesing-area pt-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                minititle="How To Working"
                title="How it Work processing"
                className="mb-20 text-center"
              />
            </div>
            <div className="col-lg-4 col-xl-3 col-md-6 mb-20">
              <Cetagorisv1
                img={search}
                title="Search Iteam"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit ac "
                link={true}
                linktext="Step 1"
                className="work-procesing imgbg-1"
              />
            </div>
            <div className="col-lg-4 col-xl-3 col-md-6 mb-20">
              <Cetagorisv1
                img={support2}
                title="24/7 Support"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit ac "
                link={true}
                linktext="Step 2"
                className="work-procesing imgbg-2"
              />
            </div>
            <div className="col-lg-4 col-xl-3 col-md-6 mb-20">
              <Cetagorisv1
                img={dron}
                title="First Dalevary"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit ac "
                link={true}
                linktext="Step 3"
                className="work-procesing imgbg-3"
              />
            </div>
            <div className="d-lg-none d-xl-block col-xl-3 col-md-6 mb-20">
              <Cetagorisv1
                img={proces3}
                title="Enjoy the Product"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit ac "
                link={true}
                linktext="Step 4"
                className="work-procesing imgbg-4"
              />
            </div>
          </div>
        </div>
      </div>
      {/* work-processing-area-end */}
      {/* Our-Newsletter-area-start */}
      <div className="newsletter-area pt-50">
        <NewsLetter
          background={backgrounds}
          smalltitle="Our Newsletter"
          titles="Get weekly update. Sign up and get up to"
          parsent="20% off"
          title="your first purchase"
          img={newsletter2}
        />
      </div>
      {/* Our-Newsletter-area-end */}
    </Fragment>
  );
};
export default About;
