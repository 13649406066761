import React, { Fragment } from "react";
import "./Feature.css";
const Fewture = (props) => {
  return (
    <Fragment>
      <div className="fewture-content">
        <div className="fewture-img">
          <img src={props.shiping} alt="shiping" />
        </div>
        <div className="f-content">
          <h3>{props.title}</h3>
          <p>{props.contents}</p>
        </div>
      </div>
    </Fragment>
  );
};

export default Fewture;
