import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../node_modules/swiper/swiper-bundle.min.css";
import breastedsuit from "../../assets/cetagoris/breasted-suit.png";
import manShirt from "../../assets/cetagoris/man-shirt.png";
import manshoe from "../../assets/cetagoris/man-shoe.png";
import womanbag from "../../assets/cetagoris/woman-bag.png";
import womandress from "../../assets/cetagoris/woman-dress.png";
import Categoris from "../categories/Categoris";
import "./CategoriesSlider.css";
const CategoriesSlider = () => {
  return (
    <Fragment>
      <div className={"categories-slider"}>
        <Swiper
          spaceBetween={15}
          slidesPerView={1}
          navigation={{
            prevEl: ".button-prev-slide2",
            nextEl: ".button-next-slide2",
          }}
          loop={true}
          modules={[Navigation]}
          breakpoints={{
            576: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            991: {
              slidesPerView: 4,
            },
            1320: {
              slidesPerView: 5,
            },
          }}
        >
          <SwiperSlide>
            <Link to="#">
              <Categoris
                img={manShirt}
                title="Man Shirts"
                content="38 Available"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="#">
              <Categoris
                img={breastedsuit}
                title="Breasted Suit"
                content="23 Available"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="#">
              <Categoris
                img={manshoe}
                title="man shoe"
                content="12 Available"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="#">
              <Categoris
                img={womanbag}
                title="Woman Bag"
                content="21 Available"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="#">
              <Categoris
                img={womandress}
                title="Woman Dress"
                content="44 Available"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="#">
              <Categoris
                img={manShirt}
                title="Man Shirts"
                content="38 Available"
              />
            </Link>
          </SwiperSlide>

          <SwiperSlide>
            <Link to="#">
              <Categoris
                img={manShirt}
                title="Man Shirts"
                content="38 Available"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="#">
              <Categoris
                img={breastedsuit}
                title="Breasted Suit"
                content="23 Available"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="#">
              <Categoris
                img={manshoe}
                title="man shoe"
                content="12 Available"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="#">
              <Categoris
                img={womanbag}
                title="Woman Bag"
                content="21 Available"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="#">
              <Categoris
                img={womandress}
                title="Woman Dress"
                content="44 Available"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="#">
              <Categoris
                img={manShirt}
                title="Man Shirts"
                content="38 Available"
              />
            </Link>
          </SwiperSlide>
        </Swiper>
        <div className="next-prev">
          <div className="button-next-slide2">
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
          <div className="button-prev-slide2">
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default CategoriesSlider;
