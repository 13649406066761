import React, { Fragment } from "react";
import "./TextAreaFild.css";

const TextAreaFild = (props) => {
  return (
    <Fragment>
      <div className={`single-input ${props.className ? props.className : ""}`}>
        <textarea placeholder={props.placeholder} name={props.name} />
      </div>
    </Fragment>
  );
};
export default TextAreaFild;
