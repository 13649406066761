import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAngleRight,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import jcb from "../../assets/footer/JCB.png";
import app from "../../assets/footer/app-store.png";
import discover from "../../assets/footer/discover.png";
import google from "../../assets/footer/google-play.png";
import mastercard from "../../assets/footer/mastercard.png";
import paypal from "../../assets/footer/paypal.png";
import visa from "../../assets/footer/visa.png";
import logo from "../../assets/logo/logo.png";
import "./Footer.css";
const Footer = () => {
  return (
    <Fragment>
      {/* footer-area-start */}
      <footer>
        <div className="footer-top pt-70 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 mb-20">
                <div className="footer-widget">
                  <div className="footer-about-brand">
                    <div className="footer-logo">
                      <Link to={"#"}><img src={logo} alt="logo" /></Link>
                    </div>
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum, accusantium!</p>
                    <div className="footer-email-contact">
                      <span>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <Link to={"#"}>
                          contact@company.com
                        </Link>
                      </span>
                      <span>
                        <FontAwesomeIcon icon={faPhone} />
                        <Link to={"#"}>+001 2233 456</Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-20">
                <div className="footer-widget footer-widget-1">
                  <h2>Customer Services</h2>
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faAngleRight} />
                      <Link to={"about"}>Company Profile</Link>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faAngleRight} />
                      <Link to={"about"}>About</Link>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faAngleRight} />
                      <Link to={"contact"}>Help Center</Link>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faAngleRight} />
                      <Link to={"contact"}>Career</Link>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faAngleRight} />
                      <Link to={"about"}>Features</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-20">
                <div className="footer-widget footer-widget-1">
                  <h2>Useful links</h2>
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faAngleRight} />
                      <Link to={"shop"}>Shop</Link>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faAngleRight} />
                      <Link to={"cart"}>Cart</Link>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faAngleRight} />
                      <Link to={"faq"}>FAQ</Link>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faAngleRight} />
                      <Link to={"contact"}>Contact</Link>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faAngleRight} />
                      <Link to={"wishlist"}>Wishlist</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-20">
                <div className="footer-widget">
                  <h2>Download App</h2>
                  <span>Save $3 With App & New User only</span>
                  <div className="download-app">
                    <Link
                      to={"#"}
                    >
                      <img src={google} alt="google" />
                    </Link>
                    <Link to={"#"}>
                      <img src={app} alt="app" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer-bottom-social">
                  <div className="footer-social-link">
                    <ul>
                      <li>
                        {" "}
                        <Link to={"#"}>
                          <FontAwesomeIcon icon={faFacebookF} />
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to={"#"}>
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </Link>
                      </li>
                      <li>
                        <Link to={"#"}>
                          <FontAwesomeIcon icon={faTwitter} />
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to={"#"}>
                          <FontAwesomeIcon icon={faYoutube} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="copyright">
                    <p>
                      © 2023. All rights reserved by{" "}
                      <Link to={"#"}>Shopx</Link>
                    </p>
                  </div>
                  <div className="payment-options">
                    <img src={paypal} alt="paypal" />
                    <img src={mastercard} alt="mastercard" />
                    <img src={visa} alt="visa" />
                    <img src={jcb} alt="jcb" />
                    <img src={discover} alt="discover" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* footer-area-end */}
    </Fragment>
  );
};
export default Footer;
