import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCalendarDays,
  faComments,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import blogbig from "../../assets/blog-details/blog-big.jpg";
import blogd1 from "../../assets/blog-details/blog-d1.png";
import blogd2 from "../../assets/blog-details/blog-d2.png";
import blogd3 from "../../assets/blog-details/blog-d3.png";
import blogd4 from "../../assets/blog-details/blog-d4.png";
import blogd5 from "../../assets/blog-details/blog-d5.png";
import blogd6 from "../../assets/blog-details/blog-d6.png";
import backgrounds from "../../assets/newsletter/newsletter1.png";
import newsletter2 from "../../assets/newsletter/newsletter2.png";
import Button from "../../components/button/Button";
import HeroSectionv2 from "../../components/herosectionv2/HeroSectionv2";
import InputField from "../../components/inputfild/InputFild";
import NewsLetter from "../../components/newsletter/NewsLetter";
import SingleReview from "../../components/singlereview/SingleReview";
import TextAreaFild from "../../components/textareafild/TextAreaFild";
import breadcrumb from "../../assets/hero/breadcrumb.jpg";
import "./BlogDetails.css";
const BlogDetails = () => {
  return (
    <Fragment>
      {/* herosectionv2-area-start */}
      <HeroSectionv2 title="Blog-Details" homelink="Home" breadcrumb={breadcrumb} pagename="Blog-Details" />
      {/* herosectionv2-area-end */}
      {/* blog-details-area-start */}
      <div className="blog-details-area section-ptb">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="blog-details-left">
                <div className="blog-left-title">
                  <h2>Blissful Pair Enjoying Home Shopping Adventure Together</h2>
                </div>
                <div className="blogs-detais-meta">
                  <div className="blog-meta-single">
                    <Link to={"#"}>
                      <FontAwesomeIcon icon={faUser} />
                      <span>By Admin</span>
                    </Link>
                  </div>
                  <div className="blog-meta-single ">
                    <Link to={"#"}>
                      <FontAwesomeIcon icon={faCalendarDays} />
                      <span>14 / 02 / 2024</span>
                    </Link>
                  </div>
                  <div className="blog-meta-single">
                    <Link to={"#"}>
                      <FontAwesomeIcon icon={faComments} />
                      <span>15 Comments</span>
                    </Link>
                  </div>
                </div>
                <div className="blog-details-content">
                  <p>
                    Habitasse per feugiat aliquam luctus accumsan curae, suspendisse aliquam taciti eros neque,
                    aenean sit iaculis risus commodo ut sociosqu rhoncus potenti tristique placerat sit tempus, 
                    duis erat feugiat cras sociosqu porta ut praesent
                    ,fermentum donec convallis tellus vulputate duis nibh rhoncus phasellus dui massa nisl.
                  </p>
                  <div className="blog-big-img">
                    <img src={blogbig} alt="blogbig" />
                  </div>
                  <p className="blog-bottom-content">
                    Email is a crucial channel in any marketing mix, and never has this been truer than for 
                    today’s entrepreneur. Curious what to say? How to say it? How often to hit “send”? 
                    Each bite-sized lesson delivers core concepts, guiding questions, and tactical
                    how-to resources.
                  </p>
                  <div className="blog-details-qustion">
                    <ul>
                      <li>
                         Duis hendrerit velit scelerisque felis tempus.
                      </li>
                      <li>
                         Duis hendrerit velit scelerisque felis tempus, 
                      </li>
                      <li>
                         Integer condimentum ac sapien quis maximus.
                      </li>
                    </ul>
                  </div>
                  <div className="blog-testimonial">
                  <div className="blog-testimonial-content">
                    <blockquote>
                    <p>
                    “Email is a crucial channel in any marketing mix, and never has this been
                    truer than for today’s entrepreneur. Curious what to say? How to say it? How often to hit “send”? 
                    Each bite-sized lesson delivers core concepts, guiding questions, and tactical how-to resources.”
                    </p>
                    </blockquote>
                    <p>
                      HR Harun <span> CEO of oshop</span>
                    </p>
                   </div>
                  </div>
                  <p>
                    Email is a crucial channel in any marketing mix, and never has this been truer
                    than for today’s entrepreneur. Curious what to say? How to say it? How often to hit
                    “send”? Each bite-sized lesson delivers core concepts, guiding questions, and tactical 
                    how-to resources.
                  </p>
                  <div className="blog-bottom-img">
                    <div className="row">
                      <div className="col-6">
                        <img src={blogd1} alt="blogd1" />
                      </div>
                      <div className="col-6">
                        <img src={blogd2} alt="blogd2" />
                      </div>
                    </div>
                  </div>
                  <p>
                  The SuperRep 2 evolves an important facet of modern fitness — training as
                  sport — and the idea that it's deserving of footwear designed to support the 
                  performance needs specific to these movements. That’s why the shoe keeps the tech 
                  from the original SuperRep, like the forefoot Zoom Air units for pop on jumps, a 
                  reinforced rand to brace against lateral bounds and a flexible “burpee break” for 
                  everyone’s favorite total-body punisher.
                  </p>
                  <div className="blog-left-tags-social">
                    <div className="tags">
                      <h6>Tag:</h6>
                      <span>#e-Commerce</span>
                      <span>#Shopping</span>
                      <span>#Data</span>
                    </div>
                    <div className="blog-details-social">
                      <h6>Share:</h6>
                      <span>
                        <Link to={"#"}>
                          <FontAwesomeIcon icon={faFacebookF} />
                        </Link>
                      </span>
                      <span>
                        {" "}
                        <Link to={"#"}>
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </Link>
                      </span>
                      <span>
                        <Link to={"#"}>
                          <FontAwesomeIcon icon={faTwitter} />
                        </Link>
                      </span>
                      <span>
                        <Link to={"#"}>
                          <FontAwesomeIcon icon={faYoutube} />
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className="werite-comment">
                    <h2>Leave a Reply</h2>
                    <form>
                      <div className="row">
                        <div className="col-lg-6 mb-20">
                          <InputField
                            placeholder="Name*"
                            type="text"
                            name="name"
                            className="blog-dt faq-form"
                          />
                        </div>
                        <div className="col-lg-6 mb-20">
                          <InputField
                            placeholder="Email*"
                            type="text"
                            name="email"
                            className="blog-dt faq-form"
                          />
                        </div>
                        <div className="col-lg-12">
                          <TextAreaFild
                            placeholder="Your Message"
                            type="massage"
                            className="blog-massage faq-massage"
                          />
                          <Button
                            buttonText="Submit Comment"
                            className="blog-dt-btn faq-btn"
                            type="button"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="public-comment">
                    <SingleReview title="Comments ( 04 )" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="blog-dt-right">
                <div className="latest-post-wraper search-form mb-20">
                  <h2>Latest Post</h2>
                  <div className="latest-single mb-20">
                    <div className="row">
                      <div className="col-lg-6">
                      <div className="latest-post-img">
                      <img src={blogd3} alt="blogd3" />
                      <div className="post-date">
                        <FontAwesomeIcon icon={faCalendarDays} />
                        <span>14 feb 2024</span>
                      </div>
                    </div>
                      </div>
                      <div className="col-lg-6">
                       <p>Panels with websites a city connections</p>
                      </div>
                    </div>
                  </div>
                  <div className="latest-single mb-20">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="latest-post-img">
                        <img src={blogd4} alt="blogd4" />
                         <div className="post-date">
                          <FontAwesomeIcon icon={faCalendarDays} />
                          <span>14 feb 2024</span>
                         </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                      <p>Company programm as our standing near desk</p>
                      </div>
                    </div>
                  </div>
                  <div className="latest-single">
                    <div className="row">
                    <div className="col-lg-6">
                      <div className="latest-post-img">
                        <img src={blogd5} alt="blogd5" />
                        <div className="post-date">
                          <FontAwesomeIcon icon={faCalendarDays} />
                          <span>14 feb 2024</span>
                        </div>
                       </div>
                      </div>
                      <div className="col-lg-6">
                        <p>Dubai’s FRAME Offers its Take on the</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-form mb-20">
                  <h2>Search</h2>
                  <form>
                    <InputField
                      placeholder="Search*"
                      type="text"
                      name="name"
                      className="search-input faq-form"
                    />
                    <Button
                      className="search-button"
                      type="button"
                      icons={faMagnifyingGlass}
                    />
                  </form>
                </div>
                <div className="blog-dt-cetagoris search-form mb-20">
                  <h2>Categories</h2>
                  <div className="single-blog-dt-cetagoty">
                    <span>Women’s Bag</span>
                    <span className="dt-total">(12)</span>
                  </div>
                  <div className="single-blog-dt-cetagoty">
                    <span>Men’s Accessories</span>
                    <span className="dt-total">(15)</span>
                  </div>
                  <div className="single-blog-dt-cetagoty">
                    <span>Boy’s Dress</span>
                    <span className="dt-total">(6)</span>
                  </div>
                  <div className="single-blog-dt-cetagoty">
                    <span>Women’s Fashion</span>
                    <span className="dt-total">(9)</span>
                  </div>
                  <div className="single-blog-dt-cetagoty">
                    <span>Fashion Accessories</span>
                    <span className="dt-total">(20)</span>
                  </div>
                  <div className="single-blog-dt-cetagoty">
                    <span>Leather Bags</span>
                    <span className="dt-total">(22)</span>
                  </div>
                  <div className="single-blog-dt-cetagoty">
                    <span>Gift for Him</span>
                    <span className="dt-total">(10)</span>
                  </div>
                </div>
                <div className="popular-tag-dt search-form mb-20">
                  <h2>Tags</h2>
                  <ul>
                    <li>
                      <Link to={"#"}>Design</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Html</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Grapic</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Development</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Product</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Css</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Fashion Store</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Ui/Ux design</Link>
                    </li>
                  </ul>
                </div>
                <div
                  className="blog-newslatter search-form "
                  style={{ backgroundImage: `url(${blogd6})` }}
                >
                  <h2>Newsletter</h2>
                  <p>Get weekly update</p>
                  <form>
                    <InputField
                      placeholder="example @gmail.com"
                      type="text"
                      name="email"
                      className="blog-newslatter-input faq-form"
                    />
                    <Button
                      buttonText="Subscribe"
                      className="blog-newslatter-btn"
                      type="button"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* blog-details-area-end */}
      {/* Our-Newsletter-area-start */}
      <div className="newsletter-area">
        <NewsLetter
          background={backgrounds}
          smalltitle="Our Newsletter"
          titles="Get weekly update. Sign up and get up to"
          parsent="20% off"
          title="your first purchase"
          img={newsletter2}
        />
      </div>
      {/* Our-Newsletter-area-end */}
    </Fragment>
  );
};
export default BlogDetails;
