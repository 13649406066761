import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Header1 from "../components/header1/Header1";
const Layout1 = () => {
  return (
    <Fragment>
      <Header1 />
      <Outlet />
      <Footer />
    </Fragment>
  );
};
export default Layout1;
