import React, { Fragment, useState } from "react";
import Slider from "react-slider";
import "./RangeSlider.css";
const MIN = 29;
const MAX = 1500;
const RangeSlider = () => {
  const [values, setValues] = useState([MIN, MAX]);
  return (
    <Fragment>
      <div className="slider-range">
        <Slider
          className={"slider"}
          value={values}
          min={MIN}
          max={MAX}
          onChange={setValues}
        />
        <div className={"values"}>
          <h4>
            <p>price:</p>${values[0]} - ${values[1]}
          </h4>
        </div>
      </div>
    </Fragment>
  );
};
export default RangeSlider;
