import {
  faEnvelope,
  faGlobe,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import office1 from "../../assets/contact/office1.png";
import office2 from "../../assets/contact/office2.png";
import office3 from "../../assets/contact/office3.png";
import HeroSectionv2 from "../../components/herosectionv2/HeroSectionv2";
import Map from "../../components/map/Map";
import SectionTitle from "../../components/sectiontitle/SectionTitle";
import breadcrumb from "../../assets/hero/breadcrumb.jpg";
import "./Contact.css";
const Contact = () => {
  return (
    <Fragment>
      {/* herosectionv2-area-start */}
      <HeroSectionv2 title="Contact" homelink="Home" breadcrumb={breadcrumb} pagename="Contact" />
      {/* herosectionv2-area-end */}
      {/* our-location-area-start */}
      <div className="location-area section-ptb">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SectionTitle
                minititle="Our Locations"
                title="Explore our offices located around the globe"
                className="mb-50 text-center"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="office-single">
                <div className="world-office-wraper">
                <h4>Canada Office</h4>
                  <img src={office1} alt="office1" />
                </div>
                <div className="office-contact-info">
                  <div className="contact-details">
                    <Link to={"#"}>
                      <FontAwesomeIcon icon={faEnvelope} />{" "}
                      contact.sale@oshop.com
                    </Link>
                    <Link to={"#"}>
                      <FontAwesomeIcon icon={faGlobe} /> oshop_info.com
                    </Link>
                    <Link to={"#"}>
                      <FontAwesomeIcon icon={faPhone} /> 686-324-6838
                    </Link>
                  </div>
                  <div className="contact-button">
                    <Link to={"#"}>View Location</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="office-single">
                <div className="world-office-wraper">
                <h4>China Office</h4>
                  <img src={office3} alt="office1" />
                </div>
                <div className="office-contact-info">
                  <div className="contact-details">
                    <Link to={"#"}>
                      <FontAwesomeIcon icon={faEnvelope} />{" "}
                      contact.sale@oshop.com
                    </Link>
                    <Link to={"#"}>
                      <FontAwesomeIcon icon={faGlobe} /> oshop_info.com
                    </Link>
                    <Link to={"#"}>
                      <FontAwesomeIcon icon={faPhone} /> 686-324-6838
                    </Link>
                  </div>
                  <div className="contact-button">
                    <Link to={"#"}>View Location</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="office-single">
                <div className="world-office-wraper">
                <h4>Bangladesh Office</h4>
                  <img src={office2} alt="office1" />
                </div>
                <div className="office-contact-info">
                  <div className="contact-details">
                    <Link to={"#"}>
                      <FontAwesomeIcon icon={faEnvelope} />{" "}
                      contact.sale@oshop.com
                    </Link>
                    <Link to={"#"}>
                      <FontAwesomeIcon icon={faGlobe} /> oshop_info.com
                    </Link>
                    <Link to={"#"}>
                      <FontAwesomeIcon icon={faPhone} /> 686-324-6838
                    </Link>
                  </div>
                  <div className="contact-button">
                    <Link to={"#"}>View Location</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* our-location-area-end */}
      {/* contact-form-and-map-area-start */}
      <div className="contact-area section-ptb">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="gogle-maps">
                <div>
                  <Map />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* AIzaSyDo1dWYvv6gMz84wuYKp2bt3f1KkUKCTxw */}
      {/* contact-form-and-map-area-end */}
    </Fragment>
  );
};
export default Contact;
