import {
  faCalendarDays,
  faComments,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import React, { Fragment } from "react";
import blog1 from "../../assets/blog/blog1.jpg";
import blog2 from "../../assets/blog/blog2.jpg";
import blog3 from "../../assets/blog/blog3.jpg";
import blog4 from "../../assets/blog/blog4.jpg";
import blog5 from "../../assets/blog/blog5.jpg";
import blog6 from "../../assets/blog/blog6.jpg";
import blog7 from "../../assets/blog/blog7.jpg";
import blog8 from "../../assets/blog/blog8.jpg";
import blog9 from "../../assets/blog/blog9.jpg";
import backgrounds from "../../assets/newsletter/newsletter1.png";
import newsletter2 from "../../assets/newsletter/newsletter2.png";
import BlogCard from "../../components/blogcard/BlogCard";
import HeroSectionv2 from "../../components/herosectionv2/HeroSectionv2";
import NewsLetter from "../../components/newsletter/NewsLetter";
import Pagenation from "../../components/pagenation/Pagenation";
import breadcrumb from "../../assets/hero/breadcrumb.jpg";
const Blog = () => {
  return (
    <Fragment>
      {/* herosectionv2-area-start */}
      <HeroSectionv2 title="Blog" homelink="Home" breadcrumb={breadcrumb} pagename="Blog" />
      {/* herosectionv2-area-end */}
      {/* all-blogs-heare-start */}
      <div className="blog-area pt-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6  mb-20">
              <BlogCard
                img={blog1}
                user={faUser}
                calender={faCalendarDays}
                commentsicon={faComments}
                comments="2 Comments"
                authorposition="By Admin"
                date="14 feb 2024"
                linktitle="Happy couple having fun while
                online shopping"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-20">
              <BlogCard
                img={blog2}
                user={faUser}
                calender={faCalendarDays}
                commentsicon={faComments}
                comments="2 Comments"
                authorposition="By Admin"
                date="14 feb 2024"
                linktitle="Young couple pointing at window
                shopping"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-20">
              <BlogCard
                img={blog3}
                user={faUser}
                calender={faCalendarDays}
                commentsicon={faComments}
                comments="2 Comments"
                authorposition="By Admin"
                date="14 feb 2024"
                linktitle="Out Shopping Together and Then Returning Home"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-20">
              <BlogCard
                img={blog4}
                user={faUser}
                calender={faCalendarDays}
                commentsicon={faComments}
                comments="2 Comments"
                authorposition="By Admin"
                date="14 feb 2024"
                linktitle="Happy beautiful couple posing with
                shopping violet"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-20">
              <BlogCard
                img={blog5}
                user={faUser}
                calender={faCalendarDays}
                commentsicon={faComments}
                comments="2 Comments"
                authorposition="By Admin"
                date="14 feb 2024"
                linktitle="with the challenges posed by the COVID-19 pandemic,"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-20">
              <BlogCard
                img={blog6}
                user={faUser}
                calender={faCalendarDays}
                commentsicon={faComments}
                comments="2 Comments"
                authorposition="By Admin"
                date="14 feb 2024"
                linktitle="The atmosphere is filled with warmth and anticipation"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-20">
              <BlogCard
                img={blog7}
                user={faUser}
                calender={faCalendarDays}
                commentsicon={faComments}
                comments="2 Comments"
                authorposition="By Admin"
                date="14 feb 2024"
                linktitle="In this haven of tiny apparel, the air is filled with the promise"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-20">
              <BlogCard
                img={blog8}
                user={faUser}
                calender={faCalendarDays}
                commentsicon={faComments}
                comments="2 Comments"
                authorposition="By Admin"
                date="14 feb 2024"
                linktitle="elegant young man choosing
                clothes in mall"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-20">
              <BlogCard
                img={blog9}
                user={faUser}
                calender={faCalendarDays}
                commentsicon={faComments}
                comments="2 Comments"
                authorposition="By Admin"
                date="14 feb 2024"
                linktitle="The atmosphere is filled with warmth and anticipation"
              />
            </div>
            <div className="col-lg-12">
              <div className="blog-pagenation">
                <Pagenation />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* all-blogs-heare-end */}
      {/* Our-Newsletter-area-start */}
      <div className="newsletter-area pt-70">
        <NewsLetter
          background={backgrounds}
          smalltitle="Our Newsletter"
          titles="Get weekly update. Sign up and get up to"
          parsent="20% off"
          title="your first purchase"
          img={newsletter2}
          alt=""
        />
      </div>
      {/* Our-Newsletter-area-end */}
    </Fragment>
  );
};
export default Blog;
