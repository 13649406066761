import React, { Fragment } from "react";
import big1 from "../../assets/all-shop/shopdetails-big1.png";
import big2 from "../../assets/all-shop/shopdetails-big2.png";
import big3 from "../../assets/all-shop/shopdetails-big3.png";
import big4 from "../../assets/all-shop/shopdetails-big4.png";
import small1 from "../../assets/all-shop/shopdetails1.png";
import small2 from "../../assets/all-shop/shopdetails2.png";
import small3 from "../../assets/all-shop/shopdetails3.png";
import small4 from "../../assets/all-shop/shopdetails4.png";
import "./EmbroideredLeft.css";
const EmbroideredLeft = () => {
  return (
    <Fragment>
      <div className="embroidered-left-img">
        <div className="tab-content embroidered-big-img" id="myTabContents">
          <div
            className="tab-pane fade show active"
            id="red-dress-toggle"
            role="tabpanel"
            aria-labelledby="red-dress"
            tabIndex="0"
          >
            <img src={big1} alt="bigimg" />
          </div>
          <div
            className="tab-pane fade"
            id="blue-green-dress-toggle"
            role="tabpanel"
            aria-labelledby="blue-green-dress"
            tabIndex="0"
          >
            <img src={big2} alt="bigimg" />
          </div>
          <div
            className="tab-pane fade"
            id="red-dress-2-toggle"
            role="tabpanel"
            aria-labelledby="red-dress-2"
            tabIndex="0"
          >
            <img src={big3} alt="bigimg" />
          </div>
          <div
            className="tab-pane fade"
            id="blue-purple-toggle"
            role="tabpanel"
            aria-labelledby="ble-purple"
            tabIndex="0"
          >
            <img src={big4} alt="bigimg" />
          </div>
        </div>
        <div
          className="row nav embroidered-small-img"
          id="myTab"
          role="tablist"
        >
          <div className="col-3 nav-item" role="presentation">
            <button
              className="nav-link active"
              id="red-dress"
              data-bs-toggle="tab"
              data-bs-target="#red-dress-toggle"
              type="button"
              role="tab"
              aria-controls="red-dress-toggle"
              aria-selected="true"
            >
              <img src={small1} alt="smalimg1" />
            </button>
          </div>
          <div className="col-3 nav-item" role="presentation">
            <button
              className="nav-link"
              id="blue-green-dress"
              data-bs-toggle="tab"
              data-bs-target="#blue-green-dress-toggle"
              type="button"
              role="tab"
              aria-controls="blue-green-dress-toggle"
              aria-selected="false"
            >
              <img src={small2} alt="smalimg1" />
            </button>
          </div>
          <div className="col-3 nav-item" role="presentation">
            <button
              className="nav-link"
              id="red-dress-2"
              data-bs-toggle="tab"
              data-bs-target="#red-dress-2-toggle"
              type="button"
              role="tab"
              aria-controls="red-dress-2-toggle"
              aria-selected="false"
            >
              <img src={small3} alt="smalimg1" />
            </button>
          </div>
          <div className="col-3 nav-item last" role="presentation">
            <button
              className="nav-link"
              id="ble-purple"
              data-bs-toggle="tab"
              data-bs-target="#blue-purple-toggle"
              type="button"
              role="tab"
              aria-controls="blue-purple-toggle"
              aria-selected="false"
            >
              <img src={small4} alt="smalimg1" />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default EmbroideredLeft;
