import { faQuoteRight, faStar } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../node_modules/swiper/swiper-bundle.min.css";
import author2 from "../../assets/testimonial/author2.png";
import author3 from "../../assets/testimonial/author3.png";
import author4 from "../../assets/testimonial/author4.png";
import Testimonial from "../testimonial/Testimonial";
import "./TestimonialSlide1.css";
const TestimonialSlide1 = () => {
  return (
    <Fragment>
      <div className="testimonial-slidev1">
        <Swiper
          spaceBetween={15}
          loop={true}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          className="mySwiper"
          breakpoints={{
            576: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            991: {
              slidesPerView: 1,
            },
            1200: {
              slidesPerView: 1,
            },
          }}
        >
          <SwiperSlide>
            <Testimonial
              icon={faQuoteRight}
              star={faStar}
              content="All the Lorem Ipsum generatolrs on the In our ternet repeat predefined chunks as necessary ki hol making
            true generator on the Internet a our combned with a
            of model sentence
            ."
              authorname="Juliyet Jhon"
              position="Wp Developer"
              img={author2}
              className="testimonialv1-wraper"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Testimonial
              icon={faQuoteRight}
              star={faStar}
              content="All the Lorem Ipsum generatolrs on the In our ternet repeat predefined chunks as necessary ki hol making
            true generator on the Internet a our combned with a
            of model sentence
            "
              authorname="Rhodes Jhon"
              position="UIUX Designer"
              img={author3}
              className="testimonialv1-wraper"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Testimonial
              icon={faQuoteRight}
              star={faStar}
              content="All the Lorem Ipsum generatolrs on the In our ternet repeat predefined chunks as necessary ki hol making
            true generator on the Internet a our combned with a
            of model sentence
            "
              authorname="Steel Golden"
              position="Product Designer"
              img={author4}
              className="testimonialv1-wraper"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </Fragment>
  );
};
export default TestimonialSlide1;
