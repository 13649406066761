import React, { Fragment } from "react";
import "./Map.css";
const Map = () => {
  return (
    <Fragment>
      <div className="google-map">
        <iframe
          title="Responsive Goggle Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.0362016315!2d-74.30934755060792!3d40.697539940608515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1698771625172!5m2!1sen!2sbd"
          className="my-map"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </Fragment>
  );
};
export default Map;
