import React, { Fragment } from "react";
import faq1 from "../../assets/faq/faq1.png";
import backgrounds from "../../assets/newsletter/newsletter1.png";
import newsletter2 from "../../assets/newsletter/newsletter2.png";
import Button from "../../components/button/Button";
import HeroSectionv2 from "../../components/herosectionv2/HeroSectionv2";
import InputField from "../../components/inputfild/InputFild";
import NewsLetter from "../../components/newsletter/NewsLetter";
import TextAreaFild from "../../components/textareafild/TextAreaFild";
import breadcrumb from "../../assets/hero/breadcrumb.jpg";
import "./Faq.css";
const Faq = () => {
  return (
    <Fragment>
      {/* herosectionv2-area-start */}
      <HeroSectionv2 title="Faq" homelink="Home" breadcrumb={breadcrumb} pagename="Faq" />
      {/* herosectionv2-area-end */}
      {/* faq-area-start */}
      <div className="faq-main-area pt-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 md-mb-30">
              <div className="faq-collaps">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <span>1</span> How can I return my purchase?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          There are many variations of passages of lorem Ipsums
                          available, but as our smajority have suffered
                          alteratio in some form, by injected as humour, or
                          domised words which don't look even slightly
                          believable.If you are going to use a passage of Lorem
                          Ipsum, you needs to be sure majority have suffered
                          alteration in some form
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <span>2</span> Can the products be applied during?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          There are many variations of passages of lorem Ipsums
                          available, but as our smajority have suffered
                          alteratio in some form, by injected as humour, or
                          domised words which don't look even slightly
                          believable.If you are going to use a passage of Lorem
                          Ipsum, you needs to be sure majority have suffered
                          alteration in some form
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <span>3</span> Can ink or other stains be washed off?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          There are many variations of passages of lorem Ipsums
                          available, but as our smajority have suffered
                          alteratio in some form, by injected as humour, or
                          domised words which don't look even slightly
                          believable.If you are going to use a passage of Lorem
                          Ipsum, you needs to be sure majority have suffered
                          alteration in some form
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        <span>4</span> How do return anytime products?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          There are many variations of passages of lorem Ipsums
                          available, but as our smajority have suffered
                          alteratio in some form, by injected as humour, or
                          domised words which don't look even slightly
                          believable.If you are going to use a passage of Lorem
                          Ipsum, you needs to be sure majority have suffered
                          alteration in some form
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        <span>5</span> wait before painting in case of new
                        order?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          There are many variations of passages of lorem Ipsums
                          available, but as our smajority have suffered
                          alteratio in some form, by injected as humour, or
                          domised words which don't look even slightly
                          believable.If you are going to use a passage of Lorem
                          Ipsum, you needs to be sure majority have suffered
                          alteration in some form
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        <span>6</span> What is the difference between emulsions?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          There are many variations of passages of lorem Ipsums
                          available, but as our smajority have suffered
                          alteratio in some form, by injected as humour, or
                          domised words which don't look even slightly
                          believable.If you are going to use a passage of Lorem
                          Ipsum, you needs to be sure majority have suffered
                          alteration in some form
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSaven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSaven"
                        aria-expanded="false"
                        aria-controls="collapseSaven"
                      >
                        <span>7</span> What are popular free webpage builders?
                      </button>
                    </h2>
                    <div
                      id="collapseSaven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSaven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          There are many variations of passages of lorem Ipsums
                          available, but as our smajority have suffered
                          alteratio in some form, by injected as humour, or
                          domised words which don't look even slightly
                          believable.If you are going to use a passage of Lorem
                          Ipsum, you needs to be sure majority have suffered
                          alteration in some form
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-user-form">
                <div className="faq-img">
                  <img src={faq1} alt="faq1" />
                </div>
                <div className="faq-title">
                  <h2>Ask any Question</h2>
                  <p>
                    Sed primis eu conubia erat ut nam vitae a habitant dui
                    adipiscing as dignissim eu a ad venenatis.
                  </p>
                </div>
                <div className="faq-form">
                  <form>
                    <div className="row">
                      <div className="col-lg-6 mb-20">
                        <InputField
                          placeholder="Your Name"
                          name="name"
                          type="text"
                          className="faq-form"
                        />
                      </div>
                      <div className="col-lg-6 mb-20">
                        <InputField
                          placeholder="Email Address"
                          name="email"
                          type="text"
                          className="faq-form"
                        />
                      </div>
                      <div className="col-lg-12">
                        <TextAreaFild
                          placeholder="Write your message"
                          name="massage"
                          className="faq-massage"
                        />
                        <Button
                          buttonText="Send Now"
                          className="faq-btn"
                          type="button"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* faq-area-end */}
      {/* Our-Newsletter-area-start */}
      <div className="newsletter-area pt-70">
        <NewsLetter
          background={backgrounds}
          smalltitle="Our Newsletter"
          titles="Get weekly update. Sign up and get up to"
          parsent="20% off"
          title="your first purchase"
          img={newsletter2}
        />
      </div>
      {/* Our-Newsletter-area-end */}
    </Fragment>
  );
};
export default Faq;
