import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./PromoCardv1.css";
const PromoCardv1 = (props) => {
  return (
    <Fragment>
      <div className={`promo-card ${props.cardbg}`}>
      <img className="promo-shape" src={props.promoimg2? props.promoimg2:""} alt="bottomimg" />
        <div className="promo-content">
          <div className="promo-title">
            <h6 className={props.classNames ? props.classNames : ""}>
              {props.upto}
              <span className={props.badgebg}>{props.discountv1}</span>
              {props.discountsofv1}
            </h6>
            <h6>{props.title}</h6>
            <h3>{props.bigtitle}</h3>
            <Link className="button-c button2" to={"/shop"}>
              {props.link}
            </Link>
          </div>
          <div className="promo-card-img">
            <div className={`man-img ${props.bottomimg ? props.bottomimg: ""}`}>
              <img src={props.promoimg1} alt="bottomimg" />
            </div>
            <div className="shape-img">
              <img
                src={props.promoshape ? props.promoshape : ""}
                alt="promoshape"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default PromoCardv1;
