import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/logo/logo.png";
import "./Menu.css";
const Menu = (props) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };
  return (
    <Fragment>
      <div className="main-menu d-none d-lg-block">
        <nav>
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
              <div className="submenu">
                <ul>
                  <li>
                    <NavLink to="/">Home One</NavLink>
                  </li>
                  <li>
                    <NavLink to="/home2">Home Two</NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <NavLink to="/about">About</NavLink>
            </li>
            <li>
              <NavLink to="/shop">
                Shop
                {props.badge ? (
                  <span className="badge badge-hot">Hot</span>
                ) : (
                  ""
                )}
              </NavLink>
            </li>
            <li>
              <NavLink to="/blog">Blog</NavLink>
            </li>
            <li className="menu-item-has-children">
              <NavLink to="/">
                Pages
                {props.badge ? (
                  <span className="badge badge-new">New</span>
                ) : (
                  ""
                )}
              </NavLink>
              <div className="submenu">
                <ul>
                  <li>
                    <NavLink to="/blogdetails">Blog details</NavLink>
                  </li>
                  <li>
                    <NavLink to="/shopdetails">Shop details</NavLink>
                  </li>
                  <li>
                    <NavLink to="/wishlist">Wishlist</NavLink>
                  </li>
                  <li>
                    <NavLink to="/checkout">Checkout</NavLink>
                  </li>
                  <li>
                    <NavLink to="/cart">Cart</NavLink>
                  </li>
                  <li>
                    <NavLink to="/faq">FAQ</NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <span onClick={handleMobileMenu} className="menu-icon d-block d-lg-none">
        <FontAwesomeIcon icon={faBars} />
      </span>

      {/* mobile-menu-area */}
      <div className="mobile-menu-section">
        <div
          onClick={handleMobileMenu}
          className={`mobile-menu-overlay ${mobileMenu ? "active" : ""}`}
        ></div>
        <div className={`mobile-menu ${mobileMenu ? "active" : ""}`}>
          <div onClick={handleMobileMenu} className="mobile-menu-close">
            <div className="mobile-menu-logo">
              <Link to={"/"}>
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <h6>
              <FontAwesomeIcon icon={faXmark} />
            </h6>
          </div>
          <div className="mobile-main-menu">
            <div className="main-menu">
              <nav>
                <ul>
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <li className="accordion-item">
                      <p className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          <li className="menu-item-has-children">
                            <NavLink to="/">Home</NavLink>
                          </li>
                        </button>
                      </p>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="submenu">
                            <ul>
                              <li>
                                <NavLink to="/">Home One</NavLink>
                              </li>
                              <li>
                                <NavLink to="/home2">Home Two</NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <NavLink to="/about">About</NavLink>
                    </li>
                    <li>
                      <NavLink to="/shop">
                        Shop
                        {props.badge ? (
                          <span className="badge badge-hot">Hot</span>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/blog">Blog</NavLink>
                    </li>
                    <li className="accordion-item">
                      <p className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <li className="menu-item-has-children">
                            <NavLink to="/">
                              Pages
                              {props.badge ? (
                                <span className="badge badge-new">New</span>
                              ) : (
                                ""
                              )}
                            </NavLink>
                          </li>
                        </button>
                      </p>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="submenu">
                            <ul>
                              <li>
                                <NavLink to="/blogdetails">
                                  Blog details
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="/shopdetails">
                                  Shop details
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="/wishlist">Wishlist</NavLink>
                              </li>
                              <li>
                                <NavLink to="/checkout">Checkout</NavLink>
                              </li>
                              <li>
                                <NavLink to="/cart">Cart</NavLink>
                              </li>
                              <li>
                                <NavLink to="/faq">FAQ</NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <NavLink to="/contact">Contact</NavLink>
                    </li>
                  </div>
                </ul>
              </nav>
            </div>
            
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Menu;
