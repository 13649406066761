import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./TeamCard.css";
const TeamCard = (props) => {
  return (
    <Fragment>
      <div className="team-card">
        <div className="team-img">
          <img src={props.img} alt="team" />
          <div className="team-social-info">
            <ul>
              <li>
                <Link to={"#"}>
                  <FontAwesomeIcon icon={props.farebook} />
                </Link>
              </li>
              <li>
                <Link to={"#"}>
                  <FontAwesomeIcon icon={props.linkdin} />
                </Link>
              </li>
              <li>
                <Link to={"#"}>
                  <FontAwesomeIcon icon={props.twitter} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="team-info">
          <div className="team-text">
            <span>{props.position}</span>
            <h4>{props.name}</h4>
          </div>
          <div className="model-icon">
            <div className="icons icon1">
              <FontAwesomeIcon icon={props.plus} />
            </div>
            <div className="icons icon2">
              <FontAwesomeIcon icon={props.mainus} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default TeamCard;
